import * as React from 'react';
import { styled } from '@mui/material/styles';
import { TextValidator } from 'react-material-ui-form-validator';

const CssTextValidator = styled(TextValidator)({
    "& .Mui-error": { /*error message inside textfield*/
        color: 'red',
        fontFamily: 'Nunito, sans- serif'
    },
    "& .MuiOutlinedInput-root.Mui-error": { /*textfield border when there is error*/
        "& fieldset": {
            color: "#ff0000 !important",
            border: "2px solid #FF0000",
            '@media (max-width: 600px)': {
                border: "1px solid #FF0000",
            }
        }
    },
    "& .MuiFormHelperText-root": {
        color: "#ff0000", /*error message under textfield*/
        fontFamily: 'Poppins, sans- serif',
        fontSize: "16px",
        fontWeight: "500",
        letterSpacing: 0,
        '@media (max-width: 600px)': {
            fontSize: "10px",
            textAlign: "right"
        }
        
    },
    "& .MuiFormHelperText-root.Mui-error": {
        color: "#ff0000 !important",
        fontFamily: "Poppins"
    },
    '& label': {
        color: '#0e1111',
        fontFamily: 'Open Sans',
        fontSize: 'large'
    },
    '& label.Mui-focused': {
        color: '#0e1111',
        fontFamily: 'Poppins',
        fontSize: 'large'
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'rgb(0, 144, 119)',
    },
    '& ,MuiFormControl-root': {
        '@media (max-width: 600px)': {
            height: '32px',            
        }
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
        '@media (max-width: 600px)': {
            height: "35px",
            paddingBottom: "10px", /*/placeholder date/*/
        }
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root': { 
        fontFamily: "Poppins",
        '@media (max-width: 600px)': {
            lineHeight: "0.9em !important",
            fontSize: "14px !important",
            height: "32px",
            paddingTop: "4px"
        }       
    },
    '& .MuiOutlinedInput-root': {  /*textfield*/
        '& fieldset': {
            border: '2px solid #9CA7B2',
            fontFamily: 'Poppins',
            height: '47px',
            marginTop: "5px",
            borderRadius: "15px",
            boxShadow: "0px 5px 10px #0000001A",
            '@media (max-width: 600px)': {
                height: '33px',
                borderRadius: '10px',
                boxShadow: "0px 5px 10px #0000000A",
                border: '1.5px solid #9CA7B2',
                lineHeight: "0.9em !important",
                fontSize: "5px !important"
            }
        },
        '&:hover fieldset': {
            border: '2px solid #9CA7B2',
        },
        '&::placeholder': {
            color: 'silver !important',
        },
        '&.Mui-focused fieldset': {
            border: '2px solid #9CA7B2',
        },
    },
    '& .MuiOutlinedInput-input': {
        '& fieldset': {
            height: "33px !important",
            fontFamily: "Poppins"
        },
    },
    '& .MuiOutlinedInput-input.Mui-focused': {
        '& fieldset': {
            fontFamily: 'Poppins'
        }
    },
    '& .MuiInputBase-colorPrimary.Mui-error': {
        borderColor: 'red',
    },
    '& .MuiFormControl-root-MuiTextField-root.MuiInputBase-colorPrimary.Mui-error': {
        borderColor: 'red',
        boxShadow:'none'
    },
    
   
});

export default function StyledTextValidator(props: {
    InputLabelProps?: {},
    key?: string | number,
    name: string,
    label?: string,
    required?: boolean,
    fullWidth?: boolean,
    validators?: any[] | undefined,
    errorMessages?: string | any[] | undefined,
    value?: any,
    id?: any,
    type?: string,
    onChange?(e: any): void,
    disabled?: boolean,
    inputProps?: {},
    helperText?: JSX.Element,
    maxLenght?: string | undefined,
    max?: number | undefined,
    border?: string
}) {
    return (
        <CssTextValidator sx={{border: props.border ? props.border : "auto"} }
            autoComplete={"off"}
            InputLabelProps={props.InputLabelProps}
            key={props.key}
            label={props.label}
            id={props.id}
            floatingLabelText="test"
            hintText="test"
            name={props.name}
            required={props.required}
            fullWidth={props.fullWidth}
            validators={props.validators}
            errorMessages={props.errorMessages}
            value={props.value}
            type={props.type}
            onChange={props.onChange}
            disabled={props.disabled}
            inputProps={props.inputProps}
            helperText={props.helperText}
            maxlength={props.maxLenght}
            max={props.max}
        />
    );
}