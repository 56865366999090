

import { Fragment, useEffect, useState } from "react";
import { ServiceTypeEnum } from "../../../_common/enum/setviceTypeEnum";
import { ICustomerAppointmentManage } from "../../../_common/interface/customerAppointment/iCustomerAppointmentManage";
import { IINcomeBandManage } from "../../../_common/interface/incomeBand/iIncomeBandManage";
import { incomeBandService } from "../../../_services/incomeBand.service";
import { handleErrorResponse } from "../../../_helpers/handleResponse.helper";
import { Box, CircularProgress, FormControl, FormControlLabel, Grid, Icon, InputAdornment, InputLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { GenericAutoComplete } from "../../../_components/autocomplete/generic.autocomplete";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { CheckBoxOutlineBlankOutlined, CheckBox, EuroSymbol } from "@mui/icons-material";
import StyledTextValidator from "../../../_components/textValidator/styledTextValidator";
import { PRIMARY_COLOR } from "../../../_const/color.const";
import StyledFormControlLabel from "../../../_components/formControlLabel/styledFormControlLabel";


export const InitialDetailsStep = (params: {
    data: ICustomerAppointmentManage,
    serviceType: ServiceTypeEnum,
    onBack(): void,
    onNext(): void
    onChange(event: any): void

}) => {
    const [incomebands, setIncomeBands] = useState<Array<IINcomeBandManage>>([]);
    const [isLoading, setLoading] = useState(false);

    const [formattedMortgageAmount, setFormattedMortgageAmount] = useState('');

    useEffect(() => {

        if (params.data.mortgageAmountRequired && params.data.mortgageAmountRequired > 0) {
            setFormattedMortgageAmount(params.data.mortgageAmountRequired.toLocaleString('en-US'));
        }

        // Allow mortgage amounts up to €2,147,483,647 (int max value in db)
        ValidatorForm.addValidationRule('isMortgageAmountValid', (value) => {
            let valueNoComma = +(value.replace(/[,.]/g, ''));

            return valueNoComma <= 2147483647;  // Max int value

        });

        fetchIncomeBands();
    }, []);

    function fetchIncomeBands() {
        return incomeBandService.getAll()
            .then(
                data => {
                    setIncomeBands(data.items);
                }, handleErrorResponse)
            .finally(() => {
                setLoading(false);
            });
    }

    function getIncomeBandRadioControl(incomeBand: IINcomeBandManage) {
        return (
            <Box key={incomeBand.id} padding={0} margin={0}>
                <StyledFormControlLabel
                    key={incomeBand.id}
                    value={incomeBand.id}
                    control={<Radio sx={{ paddingTop: "0px", paddingBottom: "0px" }}
                        icon={<CheckBoxOutlineBlankOutlined sx={{ color: PRIMARY_COLOR[5], fontSize: "46px", stroke: "#ffffff", strokeWidth: 1 }} />} // Icon when unchecked
                        checkedIcon={<CheckBox sx={{ color: PRIMARY_COLOR[5], fontSize: "44px" }} />} // Icon when checked
                    />}
                    label={incomeBand.name}
                    name={'incomeBandId'}
                />
            </Box>
        );
    }


    function handleIncomeBandChange(event: any) {
        event.target.name = 'incomeBandId';
        params.onChange(event)
    }

    function handleMortgageAmountChange(e: any) {
        let inputValue = e.target.value.replace(/[,.-]/g, ''); // Remove commas, dots and negatives

        let parsedValue = parseFloat(inputValue);
        if (!isNaN(parsedValue)) {
            let formattedValue = parsedValue.toLocaleString('en-US');

            setFormattedMortgageAmount(formattedValue);

            params.onChange({
                target: {
                    name: e.target.name,
                    value: +(e.target.value.replace(/[,.]/g, '')),
                },
            });
        } else {
            setFormattedMortgageAmount('');
            params.onChange(e);
        }
    };

    return (<>
        {isLoading && <CircularProgress />}
        {!isLoading && <>
            <Box
                display='flex'
            >
                <ValidatorForm
                    className="form-side-nav"
                    onSubmit={params.onNext}
                    instantValidate>

                    <Box width={{xs: "100%", md: "90%"}}
                        display="flex"
                        gap={4}
                        flexDirection="column"                        
                        marginRight={{ xs: "0px", md: "48px" }}
                        marginLeft={{ xs: "0px", md: "48px" }}
                    >
                        {params.serviceType === ServiceTypeEnum.Mortgage && <>
                            <Box display="flex" width="100%" gap={{ xs: 1, md: 2 }} flexWrap={{ xs: "wrap", md: "nowrap" }} justifyContent="flex-start">
                                <Typography
                                    fontSize={{ xs: "12px", md: "16px" }}
                                    fontFamily="Poppins"
                                    fontStyle={{ xs: "normal", md: "italic" }}
                                    sx={{ color: "#ACACAC" }}
                                >
                                    Fields marked with <span style={{ color: "#2CA9B4" }} >*</span> are mandatory:
                                </Typography>
                            </Box>

                            <Box display="flex" width="100%" gap={{ xs: 1, md: 2 }} flexWrap={{ xs: "wrap", md: "nowrap" }} justifyContent={{ xs: "center", md: "flex-start" }}>
                                <Box width={{ xs: "320px", sm: "100%", md: "423px" }} mb={{ xs: "10px", md: "0px" }}>
                               
                                    <InputLabel shrink={false}>
                                        <Typography
                                            sx={{ color: "#282828" }}
                                            fontSize={{ xs: "14px", md: "16px" }}
                                            fontFamily="Poppins"
                                            fontWeight={{ xs: 500, md: 600 }}
                                        >Estimated Mortgage Amount Required (€):<span style={{ color: PRIMARY_COLOR[5]}}>*</span>
                                        </Typography>
                                    </InputLabel>

                                    <StyledTextValidator
                                        type="text"
                                        key="mortgageAmountRequired"
                                        name="mortgageAmountRequired"
                                        validators={[`required`, `isMortgageAmountValid`]}
                                        errorMessages={['This field is required', 'Mortgage amount exceeds maximum']}
                                        fullWidth
                                        value={formattedMortgageAmount}
                                        onChange={handleMortgageAmountChange}
                                        inputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EuroSymbol sx={{ fontSize: '20px' }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>

                                <Box width={{ xs: "320px", sm: "100%", md: "423px" }} >
                                    <InputLabel shrink={false}>
                                        <Typography
                                            sx={{ color: "#282828" }}
                                            fontSize={{ xs: "14px", md: "16px" }}
                                            fontFamily="Poppins"
                                            fontWeight={{ xs: 500, md: 600 }}
                                        >Select type of mortgage:<span style={{ color: PRIMARY_COLOR[5] }}>*</span>
                                        </Typography>
                                    </InputLabel>
                                    <GenericAutoComplete
                                        key="mortgageTypeId"
                                        dataType="MortgageTypes"
                                        label=""
                                        hasParent={false}
                                        itemId={params.data.mortgageTypeId}
                                        name={"mortgageTypeId"}
                                        onItemChange={(e) => params.onChange(e)}
                                        isRequired={true}
                                    />
                                </Box>
                            </Box>
                        </>
                        }

                        <Box ml={{xs: "15px", sm: "0px", md: "0px"} }>
                            <Typography
                                fontSize={{ xs: "14px", md: "16px" }}
                                fontFamily="Poppins"
                                fontWeight={{ xs: 500, md: 600 }}
                            >What is your income?:
                                <span style={{ color: PRIMARY_COLOR[0] }}>*</span>
                            </Typography>

                            <FormControl sx={{ columnGap:0}}>
                                <RadioGroup sx={{columnGap: 0, padding: 0} }
                                    defaultValue={params.data.incomeBandId || 1}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="incomeBandId"
                                    onChange={handleIncomeBandChange}
                                >
                                    {incomebands && incomebands.length > 0 && <>
                                        {incomebands.map((incomeBand, index) => (
                                            <Box key={index} padding={0} margin={0}>
                                                {getIncomeBandRadioControl(incomeBand)}
                                            </Box>
                                        ))}
                                    </>}
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>
                </ValidatorForm>
            </Box>
        </>}
    </>);
};
