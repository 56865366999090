import { BehaviorSubject } from "rxjs";
import { AUTH_URL } from "../_config/config.const";
import { handleResponse } from "../_helpers/handleResponse.helper";
import { getHeader } from "../_helpers/header.helper";
import { getBody } from "../_helpers/body.helper";
import { RoleEnum } from "../_common/enum";
import { IUserManage } from "../_common/interface/users/iUserManage";

const currentUser = localStorage.getItem('currentUser') || "";
var currentUserSubject: BehaviorSubject<any>;

var ___userObject = {
    roles: [],
    token: "",
    shopId: null
}

try {
    ___userObject = JSON.parse(currentUser);
    new BehaviorSubject(___userObject);
}
catch {
    ___userObject = {
        roles: [],
        token: "",
        shopId: null
    }
}

currentUserSubject = new BehaviorSubject(___userObject);

export const accountService = {
    login,
    verifyCode,
    logout,
    post,
    put,
    submit(model?: any) { return model?.id ? put(model) : post(model) },
    disable,
    forgotPassword,
    resetPassword,
    currentUser: currentUserSubject.asObservable(),
    get isAdmin() { return (currentUserSubject.value && currentUserSubject.value.roles.length && currentUserSubject.value.roles.some((a: any) => a === RoleEnum.Admin)) },
    get currentUserValue() { return currentUserSubject.value }
};

function login(email: string, password: string, rememberMe: boolean) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, rememberMe })
    };

    return fetch(`${AUTH_URL}/account/authenticate`, requestOptions)
        .then(handleResponse);
}

function verifyCode(email: string, code: string) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, code })
    };

    return fetch(`${AUTH_URL}/account/verifycode`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);
            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
    window.location.href = "/login";
}

function resetPassword(email: string, password: string, confirmPassword: string, code: string) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, confirmPassword, code })
    };

    return fetch(`${AUTH_URL}/account/resetpassword`, requestOptions)
        .then(handleResponse);
}

function forgotPassword(email: string) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email })
    };

    return fetch(`${AUTH_URL}/account/forgotpassword`, requestOptions)
        .then(handleResponse);
}

function post(model: any) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${AUTH_URL}/account/create`, requestOptions).then(handleResponse);
}

function put(model: any) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${AUTH_URL}/account/update`, requestOptions).then(handleResponse);
}

function disable(id: any) {
    const requestOptions = { method: 'DELETE', headers: getHeader() };
    return fetch(`${AUTH_URL}/account/disable/?userId=${id}`, requestOptions).then(handleResponse);
}
