import * as React from 'react';
import { styled } from '@mui/material/styles';
import { TextValidator } from 'react-material-ui-form-validator';

const CssTextValidator = styled(TextValidator)({
    "& .Mui-error": { /*error message inside textfield*/
        color: 'red',
    },
    "& .MuiOutlinedInput-root.Mui-error": { /*textfield border when there is error*/
        "& fieldset": {
            color: "#ff0000 !important",
            border: "2px solid #FF0000",
            '@media (max-width: 600px)': {
                border: "1px solid #FF0000",
            }
        }
    },
    "& .MuiFormHelperText-root": {
        marginTop: "20px",
        color: "#ff0000", /*error message under textfield*/
        fontFamily: 'Poppins, sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        paddingLeft: "0px",
        '@media (max-width: 600px)': {
            fontSize: "10px",
            textAlign: "right",
            paddingTop: "12px"
        }
    },
    "& .MuiFormHelperText-root.Mui-error": {
        color: "#ff0000 !important"
    },
    '& label': {
        color: '#0e1111',
        fontFamily: 'Open Sans',
        fontSize: 'large'
    },
    '& label.Mui-focused': {
        color: '#0e1111',
        fontFamily: 'Poppins',
        fontSize: 'large'
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'rgb(0, 144, 119)',
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
        "& textarea": {
            paddingLeft: "8px"
        },
        '@media (max-width: 600px)': {
            fontSize: "14px !important",
        }
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: '2px solid #9CA7B2',
            fontFamily: 'Poppins',
            height: '100px',
            marginTop: "5px",
            borderRadius: "15px",
            boxShadow: "0px 5px 10px #0000001A",
            paddingLeft: "5px",
            '@media (max-width: 600px)': {
                height: '120px',
                borderRadius: '10px',
                boxShadow: "0px 5px 10px #0000000A",
                border: '1.5px solid #9CA7B2',
            }
        },
        '&:hover fieldset': {
            borderColor: '#8a8a8a',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#8a8a8a',
        },
    },
    '& .MuiOutlinedInput-input.Mui-focused': {
        '& fieldset': {
            fontFamily: 'Poppins'
        }
    },
    '& .MuiInputBase-colorPrimary.Mui-error': {
        borderColor: 'blue',
    },
});

export default function StyledTextValidator(props: {
    InputLabelProps?: {},
    key?: string | number,
    name: string,
    label?: string,
    required?: boolean,
    fullWidth?: boolean,
    validators?: any[] | undefined,
    errorMessages?: string | any[] | undefined,
    value?: any,
    id?: any,
    type?: string,
    onChange?(e: any): void,
    disabled?: boolean,
    inputProps?: {},
    multiline?: boolean,
    rows?: number | undefined,
    rowsMax?: number | undefined
}) {
    return (
        <CssTextValidator
            autoComplete={"off"}
            InputLabelProps={props.InputLabelProps}
            key={props.key}
            label={props.label}
            id={props.id}
            floatingLabelText="test"
            hintText="test"
            name={props.name}
            required={props.required}
            fullWidth={props.fullWidth}
            validators={props.validators}
            errorMessages={props.errorMessages}
            value={props.value}
            type={props.type}
            onChange={props.onChange}
            disabled={props.disabled}
            inputProps={props.inputProps}
            multiline={props.multiline}
            rows={props.rows}
        />
    );
}