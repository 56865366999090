
//export const PRIMARY_COLOR = ["#102843", "#1B2F52"];
export const PRIMARY_COLOR = ["#45bdcd", "#d12765", "#45bdcd", "#bdebf2", "#daf1f5", "#3BC1CD"];
export const SECONDARY_COLOR = ["#d12765", "#d32f2f"];
export const WARNING_COLOR = ["#FFC107", "#b28704", "#ffcd38"];
export const DANGER_COLOR = ["#ed1c23", "#a51318", "#f0494f"];
export const SUCCESS_COLOR = ["#4caf50", "#4caf50", "#6fbf73"];
export const INFO_COLOR = ["#2196f3", "#1976d2"];
export const ACTUAL_COLOR = ["#4caf50", "#d12765", "#FFC107", "#00FFFF", "#DCDCDC", "#990033"];
export const BLAC_COLOR = "#000";
export const WHITE_COLOR = "#FFF";
export const PINK_COLOR = "#f8d7da";
export const PURPLE_COLOR = ["#513f8f", "#382c64"];
export const BACKGROUND_COLOR = "#f3f5f7";
export const GREY_COLOR = "#505050";

