import { Padding } from "@mui/icons-material";
import { AppBar, Box, Grid, Hidden, Icon, IconButton, Theme, ThemeProvider, Toolbar, Typography, createTheme, Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import { PRIMARY_COLOR, SECONDARY_COLOR, WHITE_COLOR } from "../../_const/color.const";
import { accountService } from "../../_services/accounts.service";



const logoImg = require('../../_assets/images/Fairstone.jpg');

const drawerWidth = 240;

const appBarSx = (theme: Theme) => ({
    flexGrow: 1,
  //  padding: 0,
    backgroundColor: WHITE_COLOR,
    [theme.breakpoints.down('xs')]: {
        background: `linear-gradient(135deg, white 210px, ${theme.palette.primary.main} 0%)`
    },
    [theme.breakpoints.down('sm')]: {
        background: `linear-gradient(135deg, white 250px, ${theme.palette.primary.main} 0%)`
    }
})

const contentShiftSx = (theme: Theme) => ({
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
})

const pageHeadingSx = (theme: Theme) => ({
    color: "white",
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    "& h6": {
        margin: "0px",
        padding: "0px",
        marginTop: "14px",
        fontWeight: "bold"
    },
    "& div": {
        color: "white"
    },
    "& label": {
        color: "white",
    },
    "& svg": {
        color: "white"
    },
    "& input": {
        color: "white"
    },
    '& label.Mui-focused': {
        color: 'white',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
    },
    "&>div>div>div>div": {
        borderBottom: "1px solid white"
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
        },
    },
})

const theme = createTheme({
    palette: {
        primary: {
            main: PRIMARY_COLOR[0],
        },
        secondary: {
            main: SECONDARY_COLOR[0]
        }
    },
    typography: {
        fontFamily: "Poppins, sans-serif",
        h3: {
            fontFamily: "Poppins, sans-serif",
            color: "#444",
            letterSpacing: "0.0075em",
            verticalAlign: "middle",
        },
        h5: {
            width: "100%",
            margin: "10px",
            marginTop: "20px",
            borderBottom: "1px solid silver"
        },
        h6: {
            fontFamily: "Poppins, sans-serif",
            color: "#444",
            letterSpacing: "0.0075em",
            verticalAlign: "middle",
            marginTop: "20px"
        }
    }
});


export const AppToolbar = (params: { toggleSidebar: () => void, isOpen: boolean }) => {

    const [isOpen, setIsOpen] = useState(params.isOpen);
    useEffect(() => {
        setIsOpen(params.isOpen);
    }, [params.isOpen]);

    function avatarOptions(name: string) {
        return {
            sx: {
                bgcolor: SECONDARY_COLOR,
                width: "50px",
                height: "50px",
                fontSize: "22px",
                margin: "0px",
                border: "0px !important",
                boxShadow: "0px 0px 5px",
                marginRight: "35px"
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    return (
        <AppBar position="fixed" sx={appBarSx}>

            <ThemeProvider theme={theme}>
            </ThemeProvider>

            <Toolbar sx={{padding: 0}}>
                <a href="https://www.askpaul.ie/"><img style={{
                            marginTop: "0px",
                            display: 'block',
                            marginLeft: '0px',
                            marginRight: 'auto', 
                            height: "80px", 
                            margin: "0px", 
                            float: "left",
                            padding: "5px"
                        }} src={logoImg}></img></a>
                {/* <Typography variant="h6" sx={{ flexGrow: 1 }}> 

                    <Box display={{ xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }}>
                        <img style={{
                            width: "calc(240px * 0.95)",
                            marginTop: "0px",
                            display: 'block',
                            marginLeft: '0px',
                            marginRight: 'auto', height: "64px", margin: "0px", float: "left"
                        }} src={logoImg}></img>
                        <span style={{ fontSize: "32px", float: "left", marginLeft: "20px" }}></span>
                    </Box> 

                    <Hidden smDown>
                        <Box sx={{ pageHeadingSx, ...(isOpen && { contentShiftSx }) }}>

                            <Grid container>
                                {/* <Grid item sm md={7}>
                                    <Box display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }}>
                                        <IconButton
                                            color="inherit"
                                            aria-label="open drawer"
                                            onClick={(e) => { params.toggleSidebar() }}
                                            edge="start"
                                            sx={{ marginLeft: theme.spacing(1), ...(isOpen && { marginLeft: theme.spacing(30) }) }}
                                        >
                                            <Icon>menu</Icon>
                                        </IconButton>
                                    </Box>
                                </Grid>
                                <Grid item sm md={12}>

                                    <Box display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }}
                                        sx={{
                                            position: "relative",
                                            float: "right",
                                            height: "100%",
                                            cursor: "pointer"
                                        }}
                                    >
                                        <Avatar
                                            {...avatarOptions(accountService.currentUserValue?.name || accountService.currentUserValue?.email)}
                                        ></Avatar>

                                    </Box> 

                                    <Box display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }}
                                        sx={{
                                            padding: "12px 12px 12px 12px",
                                            float: "right",
                                            fontSize: "16px",
                                            cursor: "pointer"
                                        }}
                                    >
                                        {accountService.currentUserValue?.name}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                    </Hidden> 

                </Typography>*/}
            </Toolbar>
        </AppBar >
    );
};