import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, Icon, InputLabel, Link, Radio, RadioGroup, Typography, useMediaQuery } from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PRIMARY_COLOR } from "../../../_const/color.const";
import { ServiceTypeEnum } from "../../../_common/enum/setviceTypeEnum";
import { NavButtons } from "../userJourneyComponents/navButtons";
import { ICustomerAppointmentManage } from "../../../_common/interface/customerAppointment/iCustomerAppointmentManage";
import { PrimaryButton } from "../../../_components/button/primary.button";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import StyledTextValidator from "../../../_components/textValidator/styledTextValidator";
import StyledTextValidatorArea from "../../../_components/textValidator/styledTextValidatorArea";
import { CheckBox, CheckBoxOutlineBlankOutlined } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";


export const CustomerDetailsStep = (params: {
    data: ICustomerAppointmentManage,
    onChange(event: any): void,
    serviceType: ServiceTypeEnum,
    servicePrice: number,
    onApplyDiscountCode(): void,
    isDiscountCodeApplied: boolean,
    isDiscountCodeValid: boolean,
    onBack(): void,
    onNext(): void,
    appointmentDate?: Date | null
}) => {

    const [dateOfBirthDate, setDateOfBirthDate] = useState();//dayjs(getTodayDateString()));
    const [isLoading, setLoading] = useState(false);
    const [isPreviousCreditIssues, setIsPreviousCreditIssues] = useState('No');
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isPhoneNoEntered, setIsPhoneNoEntered] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [emailError, setEmailError] = useState<string | null>(null);
    const [phoneError, setPhoneError] = useState<string | null>(null);
    const [isDisableAllNav, setIsDisableAllNav] = useState(false);
    const requiredMessage = 'This field is required';

    const isMobile = useMediaQuery('(max-width:600px)');


    useEffect(() => {
        if (params.data.isPreviousCreditIssues != null) {
            if (params.data.isPreviousCreditIssues === true)
                setIsPreviousCreditIssues('Yes');
            else
                setIsPreviousCreditIssues('No');
        }
    }, []);

    function handleSubmit() {

        var isInValid = validateFields();

        if (!isInValid) {
            setIsDisableAllNav(true);

            params.onNext();
            Error("");
        }
    }


    function handleIsPreviousCreditIssuesChange(event: any) {
        setIsPreviousCreditIssues(event.target.value);
        params.onChange(event);
    }

    function handlePhoneInputChange(e: any) {
        //validate first if field is populated

        //setIsPhoneValid(true);
        //setIsPhoneNoEntered(true);
        let phoneNo = { name: 'mobilePhoneNo', value: e }
        params.onChange(phoneNo);
    }

    function handleDateOfBirthChange(event: any) {
        setDateOfBirthDate(event);
        event.name = "dateOfBirth";
        params.onChange(event);
    }


    function validateFields() {

        var isError = false;

        if (params.data.mobilePhoneNo.trim() === '' || params.data.mobilePhoneNo.length === 0) {
            setIsPhoneValid(false);
            setPhoneError(requiredMessage);
            isError = true;
        }
        else if (!isValidPhoneNumber(params.data.mobilePhoneNo)) {
            setIsPhoneValid(false);
            setPhoneError("Invalid phone number");
            isError = true;
        }
        else {
            setIsPhoneValid(true);
            setPhoneError(null);
        }

        if (params.serviceType !== ServiceTypeEnum.Existing && !dateOfBirthDate) {
            setError(requiredMessage)
            isError = true;
        } else {
            setError('');
        }

        return isError;
    }
    function isValidPhoneNumber(phoneNumber: string) {
        // Trim the phone number to remove leading and trailing white spaces
        const trimmedPhoneNumber = phoneNumber.trim();

        // Regular expressions for mobile
        const mobileRegex = /^08\d{8}$/;

        // Check if the phone number matches the regex and does not contain white spaces
        const isMobileValid = mobileRegex.test(trimmedPhoneNumber);

        return isMobileValid;
    }

    function isValidEmail(email: string) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);


    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 104);

    // Format the maximum date as "YYYY-MM-DD" for use in the input
    const maxDateFormatted = maxDate.toISOString().split('T')[0];
    const minDateFormatted = minDate.toISOString().split('T')[0];

    return (
        <>
            <ValidatorForm
                className="form-side-nav"
                onSubmit={handleSubmit}
                instantValidate>

                <Grid
                    container
                    spacing={2}
                    marginLeft={{ xs: "0px", md: "30px" }}
                    paddingRight={{ xs: "36px", md: "92px" }}
                    marginRight={{ xs: "0px", md: "48px" }}
                >
                    <Grid item xs={12}>
                        <Typography
                            fontSize={{ xs: "12px", md: "16px" }}
                            fontFamily="Poppins"
                            fontStyle={{ xs: "normal", md: "italic" }}
                            sx={{ color: "#ACACAC" }}
                        >
                            Fields marked with <span style={{ color: "#2CA9B4" }} >*</span> are mandatory:
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <InputLabel shrink={false}>
                            <Typography
                                fontWeight={{ xs: "500", md: "600" }}
                                fontSize={{ xs: "12px", md: "16px" }}
                                fontFamily="Poppins"
                                sx={{ color: "#282828" }}
                            >First Name:
                                <span style={{ color: "#2CA9B4" }}>*</span>
                            </Typography>
                        </InputLabel>
                        <StyledTextValidator
                            key="firstName"
                            name="firstName"
                            validators={[`required`]}
                            errorMessages={['This field is required']}
                            fullWidth
                            value={params.data.firstName}
                            onChange={(e: any) => params.onChange(e)}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <InputLabel shrink={false}>
                            <Typography
                                fontWeight={{ xs: "500", md: "600" }}
                                fontSize={{ xs: "12px", md: "16px" }}
                                fontFamily="Poppins"
                                sx={{ color: "#282828" }}
                            >Last Name:
                                <span style={{ color: "#2CA9B4" }}>*</span>
                            </Typography>
                        </InputLabel>
                        <StyledTextValidator
                            key="lastName"
                            name="lastName"
                            validators={[`required`]}
                            errorMessages={['This field is required']}
                            fullWidth
                            value={params.data.lastName}
                            onChange={(e: any) => params.onChange(e)}
                        />
                    </Grid>


                    {params.serviceType !== ServiceTypeEnum.Existing && <>
                        <Grid item xs={12} md={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <InputLabel shrink={false}>
                                    <Typography
                                        fontWeight={{ xs: "500", md: "600" }}
                                        fontSize={{ xs: "12px", md: "16px" }}
                                        fontFamily="Poppins"
                                        sx={{ color: "#282828" }}
                                    >Date Of Birth:
                                        <span style={{ color: "#2CA9B4" }}>*</span>
                                    </Typography>
                                </InputLabel>
                                <DatePicker
                                    key="dateOfBirth"
                                    views={['year', 'month', 'day']}
                                    format="DD/MM/YYYY"
                                    disableFuture={true}
                                    value={dateOfBirthDate}
                                    onChange={(e) => handleDateOfBirthChange(e)}
                                    className={`dateOfBirthPicker ${error ? 'error' : ''}`}
                                    slotProps={{
                                        textField: {
                                            helperText: error,
                                            sx: {
                                                height: isMobile ? "32px !important" : '45px',
                                                border: isMobile ? "1px solid #9CA7B2" : '2px solid #9CA7B2',
                                                borderRadius: '15px',
                                                fontFamily: 'Poppins',
                                                marginTop: "5px",
                                                boxShadow: isMobile ? "0px 5px 10px #0000000A " : "0px 5px 10px #0000001A",
                                            }
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <InputLabel shrink={false}>
                                <Typography
                                    fontWeight={{ xs: "500", md: "600" }}
                                    fontSize={{ xs: "12px", md: "16px" }}
                                    fontFamily="Poppins"
                                    sx={{ color: "#282828" }}
                                >Occupation:
                                    <span style={{ color: "#2CA9B4" }}>*</span>
                                </Typography>
                            </InputLabel>
                            <StyledTextValidator
                                key="occupation"
                                name="occupation"
                                validators={[`required`]}
                                errorMessages={['This field is required']}
                                fullWidth
                                value={params.data.occupation}
                                onChange={(e: any) => params.onChange(e)}
                            />
                        </Grid>
                    </>}
                    <Grid item xs={12} md={params.serviceType == ServiceTypeEnum.Existing ? 6 : 4} sx={{ paddingtop: '16px!important' }}>
                        <InputLabel shrink={false}>
                            <Typography
                                fontWeight={{ xs: "500", md: "600" }}
                                fontSize={{ xs: "12px", md: "16px" }}
                                fontFamily="Poppins"
                                sx={{ color: "#282828" }}
                            >Moblie Phone:
                                <span style={{ color: "#2CA9B4" }}>*</span>
                            </Typography>
                        </InputLabel>
                        <PhoneInput
                            country={'ie'}
                            autoFormat={false}
                            disableCountryCode={true}
                            disableDropdown={true}
                            enableLongNumbers={true}
                            value={params.data.mobilePhoneNo}
                            onChange={handlePhoneInputChange}
                            placeholder="Enter mobile phone number"
                            preferredCountries={['ie']}
                            inputClass={'mobilePhoneNoInput'}
                            isValid={isPhoneValid}
                            inputProps={{
                                name: "phone",
                                required: true,
                            }}
                            containerStyle={{
                                height: isMobile ? "32px" : "47px !important",
                                marginBottom: isMobile ? "12px" : "0px"

                            }}
                            inputStyle={{
                                // height: isMobile ? "32px !important" : '47px' ,
                                border: isMobile ? "1px solid #9CA7B2" : '2px solid #9CA7B2',
                                borderRadius: '15px',
                                fontFamily: 'Poppins',
                                marginTop: "5px",
                                boxShadow: isMobile ? "0px 5px 10px #0000000A " : "0px 5px 10px #0000001A",
                            }}

                        />
                        {!isPhoneValid && <div className="requiredText">{phoneError}</div>}
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={params.serviceType == ServiceTypeEnum.Existing ? 6 : 12}
                        pt={{ xs: "0px !important", md: "auto" }}
                        mt={{ sm: "15px" }}>
                        <InputLabel shrink={false}>
                            <Typography
                                fontWeight={{ xs: "500", md: "600" }}
                                fontSize={{ xs: "12px", md: "16px" }}
                                fontFamily="Poppins"
                                sx={{ color: "#282828" }}
                            >Email:
                                <span style={{ color: "#2CA9B4" }}>*</span>
                            </Typography>
                        </InputLabel>
                        <StyledTextValidator
                            id="fabsCustomerDetailsEmailField"
                            key="email"
                            name="email"
                            type="email"
                            required={true}
                            validators={['required','isEmail']}
                            errorMessages={['This field is required', 'Email is not valid']}
                            fullWidth
                            value={params.data.email}
                            onChange={(e: any) => params.onChange(e)}

                        />
                        <Typography
                            fontSize={{ xs: "10px", md: "16px" }}
                            textAlign={{ xs: "right", md: "left" }}
                            fontFamily="Poppins"
                            color="#ff0000"
                            paddingLeft="16px"
                            paddingRight={{ xs: "12px", md: "0px" }}
                            marginTop={{ xs: "4px", md: "0px" }}
                            fontWeight="500"
                        >
                            {!isValidEmail(params.data.email) ? emailError : ""}
                        </Typography>
                    </Grid>

                    {params.serviceType === ServiceTypeEnum.Mortgage && <Grid item xs={12}>
                        <InputLabel shrink={false}>
                            <Typography
                                fontWeight={{ xs: "500", md: "600" }}
                                fontSize={{ xs: "12px", md: "16px" }}
                                fontFamily="Poppins"
                                sx={{ color: "#282828" }}
                            >Previous Credit Issues:
                                <span style={{ color: "#2CA9B4" }}>*</span>
                            </Typography>
                        </InputLabel>
                        <Grid item xs={12}>

                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="icon-radio"
                                    name="isPreviousCreditIssues"
                                    value={isPreviousCreditIssues}
                                    onChange={(e) => handleIsPreviousCreditIssuesChange(e)}
                                >
                                    <FormControlLabel sx={{
                                        color: "#282828",
                                        " & .MuiTypography-root": {
                                            fontWeight: "600",
                                            fontFamily: "Poppins",
                                            fontSize: { xs: "14px", md: "16px" }
                                        }
                                    }}
                                        value="Yes"
                                        control={
                                            <Radio sx={{ paddingTop: "2px", paddingBottom: "2px", paddingLeft: { xs: "8px", md: "5px" }, paddingRight: "2px" }}
                                                icon={<CheckBoxOutlineBlankOutlined sx={{ color: PRIMARY_COLOR[5], fontSize: { xs: "32px", md: "46px" }, stroke: "#ffffff", strokeWidth: 1 }} />}
                                                checkedIcon={<CheckBox sx={{ color: PRIMARY_COLOR[5], fontSize: { xs: "32px", md: "46px" } }} />}
                                            />
                                        }
                                        label="YES"
                                    />
                                    <FormControlLabel sx={{
                                        color: "#282828",
                                        " & .MuiTypography-root": {
                                            fontWeight: "600",
                                            fontFamily: "Poppins",
                                            fontSize: { xs: "14px", md: "16px" }
                                        }
                                    }} value="No"
                                        control={
                                            <Radio sx={{ paddingTop: "2px", paddingBottom: "2px", paddingLeft: { xs: "8px", md: "5px" }, paddingRight: "2px" }}
                                                icon={<CheckBoxOutlineBlankOutlined sx={{ color: PRIMARY_COLOR[5], fontSize: { xs: "32px", md: "46px" }, stroke: "#ffffff", strokeWidth: 1 }} />} // Icon when unchecked
                                                checkedIcon={<CheckBox sx={{ color: PRIMARY_COLOR[5], fontSize: { xs: "32px", md: "46px" } }} />} // Icon when checked
                                            />
                                        }
                                        label="NO"
                                    />
                                </RadioGroup>
                            </FormControl>

                        </Grid>
                    </Grid>}

                    <Grid item xs={12} marginBottom="20px">
                        {isPreviousCreditIssues === 'Yes' && <>
                            <InputLabel shrink={false}>
                                <Typography whiteSpace="normal"
                                    fontWeight={{ xs: "500", md: "600" }}
                                    fontSize={{ xs: "12px", md: "16px" }}
                                    fontFamily="Poppins"
                                    sx={{ color: "#282828" }}>
                                    Please explain your situation below for us to be able to help you better<span style={{ color: PRIMARY_COLOR[0] }}>*</span>
                                </Typography>
                            </InputLabel>

                            <StyledTextValidatorArea
                                key="notes"
                                name="notes"
                                validators={[`required`]}
                                errorMessages={['This field is required']}
                                fullWidth
                                value={params.data.notes}
                                onChange={(e: any) => params.onChange(e)}
                                inputProps={{ sx: { borderRadius: 4 } }}
                                multiline
                                rows={3}
                                rowsMax={3}
                            />
                            <br>
                            </br>
                            <br>
                            </br>
                        </>}


                        <InputLabel shrink={false} sx={{ marginTop: { xs: "0px", md: "0px" } }}>
                            {params.serviceType !== ServiceTypeEnum.Existing &&
                                <Typography
                                    fontWeight={{ xs: "500", md: "600" }}
                                    fontSize={{ xs: "12px", md: "16px" }}
                                    fontFamily="Poppins"
                                    sx={{ color: "#282828" }}
                                >What do you want to discuss on the call?
                                    <span style={{ color: "#2CA9B4" }}>*</span>
                                </Typography>
                            }
                            {params.serviceType == ServiceTypeEnum.Existing &&
                                <Typography
                                    fontWeight={{ xs: "500", md: "600" }}
                                    fontSize={{ xs: "12px", md: "16px" }}
                                    fontFamily="Poppins"
                                    sx={{ color: "#282828" }}
                                >How can your advisor best help you?
                                    <span style={{ color: "#2CA9B4" }}>*</span>
                                </Typography>
                            }
                        </InputLabel>
                        <StyledTextValidatorArea
                            key="appointmentDiscussionNotes"
                            name="appointmentDiscussionNotes"
                            validators={[`required`]}
                            errorMessages={['This field is required']}
                            fullWidth
                            value={params.data.appointmentDiscussionNotes}
                            onChange={(e: any) => params.onChange(e)}
                            inputProps={{ sx: { borderRadius: 4 } }}
                            multiline
                            rows={3}
                            rowsMax={3}
                        />
                    </Grid>
                    {params.serviceType === ServiceTypeEnum.Financial && <>

                        <Grid item xs={12} marginTop={{ xs: "5px", sm: "0px" }}>
                            <InputLabel shrink={false}>
                                <Typography whiteSpace="normal"
                                    fontWeight={{ xs: "500", md: "600" }}
                                    fontSize={{ xs: "12px", md: "16px" }}
                                    fontFamily="Poppins"
                                    sx={{ color: "#282828" }}
                                >If you're booking with us after taking a money masterclass at your workplace,
                                    please enter your organisations name so we can tailor our services to you
                                </Typography>

                            </InputLabel>
                            <StyledTextValidator
                                key="companyName"
                                name="companyName"
                                fullWidth
                                value={params.data.companyName}
                                onChange={(e: any) => params.onChange(e)}
                            />
                        </Grid>
                    </>}

                    {params.servicePrice > 0 &&
                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                gap={2} mt={2}
                                flexDirection={{ xs: "column", sm: "column", md: "row" }}>
                                <Box>

                                    <InputLabel shrink={false}>
                                        {(params.isDiscountCodeApplied && !params.isDiscountCodeValid) || !params.isDiscountCodeApplied &&
                                            <Typography whiteSpace="normal"
                                                fontWeight={{ xs: "500", md: "600" }}
                                                fontSize={{ xs: "12px", md: "16px" }}
                                                fontStyle={{ xs: "italic", md: "normal" }}
                                                fontFamily="Poppins"
                                                sx={{ color: "#282828" }}
                                            >If you have a discount code, please enter it here:
                                            </Typography>
                                        }
                                        {params.isDiscountCodeApplied && params.isDiscountCodeValid &&
                                            <Typography
                                                fontWeight={{ xs: "500", md: "600" }}
                                                fontSize={{ xs: "14px", md: "16px" }}
                                                fontFamily="Poppins"
                                                sx={{ color: "#282828" }}
                                            >Discount code applied!
                                            </Typography>
                                        }
                                    </InputLabel>
                                    <StyledTextValidator
                                        disabled={params.isDiscountCodeApplied}
                                        key="discountCode"
                                        name="discountCode"
                                        fullWidth
                                        value={params.data.discountCode}
                                        onChange={(e: any) => params.onChange(e)}

                                        helperText={<FormHelperText sx={{ color: '#D32F2F' }} >
                                            {!params.isDiscountCodeValid ? 'Invalid discount code' : ''}
                                        </FormHelperText>}
                                    />
                                </Box>

                                {(params.data.discountCode && params.data.discountCode.length > 0)
                                    && (!params.isDiscountCodeApplied) &&
                                    <Box mt={{ xs: 0, xm: 0, md: 3.5 }}>
                                        <PrimaryButton
                                            disabled={params.isDiscountCodeApplied && params.isDiscountCodeValid}
                                            variant={isMobile ? "fairstoneMobileApplyButton" : "fairstoneApplyButton"}
                                            onClick={params.onApplyDiscountCode}>
                                            <Box
                                                fontSize={{ xs: "15px", md: "22px" }}
                                                fontWeight="600"
                                                letterSpacing="1px"
                                                textTransform="none"
                                                display="flex"
                                                justifyContent="center"
                                                alignItems='center'>
                                                Apply
                                            </Box>
                                        </PrimaryButton>
                                    </Box>
                                }


                            </Box>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <FormControlLabel
                            disabled={isLoading}
                            label={
                                <Typography
                                    fontWeight={{ xs: "500", md: "600" }}
                                    fontSize={{ xs: "10px", md: "13px" }}
                                    fontFamily="Poppins"
                                    sx={{ color: "#282828" }}
                                >
                                    By proceeding, you agree to Fairstone Ireland, including Fairstone Asset Management DAC trading as askpaul, and our
                                    <Link sx={{ color: "#2E99A6", textDecorationColor: "#2E99A6", fontSize: { xs: "10px !important", md: "13px !important" } }} href="https://www.fairstone.ie/our-locations" target="_blank" rel="noopener noreferrer">partner firms </Link>
                                    using your information. Please review our
                                    <Link sx={{ color: "#2E99A6", textDecorationColor: "#2E99A6", fontSize: { xs: "10px !important", md: "13px !important" } }} href="https://www.askpaul.ie/wp-content/uploads/2024/01/Askpaul-Data_Privacy_Notice-1.2-Jan-24.pdf" target="_blank" rel="noopener noreferrer">data privacy notice </Link>
                                    for details.
                                </Typography>
                            }
                            name="isTermsAndConditionsAgree"
                            value={params.data.isTermsAndConditionsAgree || false}
                            onChange={(e) => params.onChange(e)}
                            control={<Checkbox sx={{ paddingRight: "2px" }} value={params.data.isTermsAndConditionsAgree} />}
                            sx={{
                                '&.MuiFormControlLabel-root': {
                                    float: { xs: "left", md: "right" },
                                },
                                '&.Mui-checked': {
                                    color: PRIMARY_COLOR[0],
                                },
                                '& .MuiSvgIcon-root': {
                                    color: PRIMARY_COLOR[0],
                                    fontSize: { xs: "32px", md: "46px" },
                                    stroke: "#ffffff",
                                    strokeWidth: 1,

                                }
                            }}
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <NavButtons
                            showBack={true}
                            showNext={true}
                            isNextComplete={params.servicePrice === 0}
                            isDisableAllNav={isDisableAllNav}
                            isSubmit={true}
                            onBack={params.onBack}
                            onNext={validateFields}
                        />
                    </Grid>
                </Grid>
            </ValidatorForm>
        </>);
}

