import { Box, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';

declare global {
    interface Window {
        Trustpilot: any;
    }
}

const TrustpilotWidget: React.FC = () => {

    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        // Trigger TrustBox widget initialization after component is mounted
        window.Trustpilot?.loadFromElement(document.querySelector('.trustpilot-widget'));
    }, []);

    return (
        <Box>
            <div className="trustpilot-widget"
                data-locale="en-IE"
                data-template-id="53aa8912dec7e10d38f59f36"
                data-businessunit-id="5d037462d32b8a00010db559"
                data-style-height={isMobile ? "95px" : "140px"}
                data-style-width="100%"
                data-theme="light"
                data-stars="5"
                data-review-languages="en">
                <a href="https://ie.trustpilot.com/review/askpaul.ie" target="_blank" rel="noopener">Trustpilot</a>
            </div>
        </Box>
    );
};

export default TrustpilotWidget;
