import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { IStandardAutocomplete } from "../../_common/interface/autocomplete/iStandardAutocomplete";
import { IDropdownData } from "../../_common/interface/dropdown/iDropdownData.interface";
import SearchIcon from '@mui/icons-material/Search';

interface IStandardExtAutocomplete extends IStandardAutocomplete {
    itemsLoader: (parentId?: number) => Promise<any>;
    isTiny?: boolean;
    open?: boolean
    onOpen?(event: React.SyntheticEvent): void,
    disableCloseOnSelect?: boolean,
}

export default function StandardAutoComplete(params: IStandardExtAutocomplete) {
    const [value, setValue] = useState<IDropdownData | null>();
    const [inputValue, setInputValue] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [items, setItems] = useState<Array<any>>([]);
    const [excludedIds, setExcludedIds] = useState<Array<number | string>>(
        params.excludedIds || []
    );

    useEffect(() => {
        if (params.excludedIds != excludedIds) {
            setExcludedIds(params.excludedIds ? params.excludedIds : []);
        }
    }, [params.excludedIds]);

    useEffect(() => {
        if (params.hasParent === true) {
            if (params.parentId ? params.parentId > 0 : null) {
                fetchItems(params.parentId);
            } else if (items.length > 0) {
                setItems([]);
                changeInput(null);
            }
        } else {
            fetchItems();
        }
    }, [params.parentId]);

    useEffect(() => {
        if (items && items.length > 0) {
            changeInput(items.find((_item: any) => _item.id === params.itemId));
        } else if (
            ((!items || items.length === 0) && value) ||
            ((!params.itemId || params.itemId === 0) && value)
        ) {
            changeInput(null);
        }
    }, [items, params.itemId]);

    function fetchItems(parentId?: number) {
        setLoading(true);
        return params
            .itemsLoader(parentId)
            .then(
                (json) => {
                    if (!json || json.length < 1) {
                        setItems([]);
                    } else {
                        // console.log('got dropdown items: ', json);
                        setItems(json);
                    }
                },
                (err) => {
                    setItems([]);
                }
            )
            .finally(() => setLoading(false));
    }

    function changeInput(newValue: any) {
        newValue = newValue ? newValue : null;
        if (newValue === value) return;
        setValue(newValue);
        params.onItemChange?.({ target: { name: params.name, value: newValue?.id } }, newValue, params.name);
    }

    return (
        <Autocomplete
            className="autocomplete"
            open={params.open}
            onOpen={params.onOpen}
            id="tags-standard"
            options={excludedIds && excludedIds.length ? items.filter(m => m.id === value || !excludedIds.some(r => r === m.id.toString())) : items}
            getOptionLabel={(option) => option.name}
            value={value || null}
            onChange={(event: any, newValue: any) => {
                changeInput(newValue);
            }}
            inputValue={inputValue || ''}
            onInputChange={(event: any, newInputValue: any) => {
                setInputValue(newInputValue);
            }} renderInput={(props) => (
                <TextField
                    {...props}

                    variant={params.variant || "outlined"}
                    value={inputValue || ''}
                    label={params.label? 
                        <>
                            {params.label}
                            <SearchIcon className="myIcon" fontSize="small" color="disabled" />
                        </> :
                        ""
                    }
                    placeholder={params.label}
                    InputLabelProps={{
                        shrink: !!value,
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: '2px solid #9CA7B2',
                                fontFamily: 'Open Sans',
                                height: '47px',
                                marginTop: "5px",
                                borderRadius: "15px",
                                boxShadow: "0px 5px 10px #0000001A",
                                '@media (max-width: 600px)': {
                                    height: '33px',
                                    borderRadius: '10px',
                                    boxShadow: "0px 5px 10px #0000000A",
                                    border: '1px solid #9CA7B2',
                                    lineHeight: "0.9em !important",
                                    fontSize: "5px !important"
                                }
                            },
                            '&:hover fieldset': {
                                borderColor: '#8a8a8a',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#8a8a8a',
                            },
                        },
                        '& .MuiInputBase-root.MuiOutlinedInput-root': {
                            '@media (max-width: 600px)': {
                                lineHeight: "0.9em !important",
                                fontSize: "14px !important",
                                height: "40px",
                                paddingTop: "4px",
                            }
                        },
                        '& .MuiInputBase-input.MuiOutlinedInput-input': {
                            '@media (max-width: 600px)': {
                                height: "33px",
                                paddingTop: "0px",
                                marginBottom: "-10px"
                            
                            }
                        },

                        '& .MuiOutlinedInput-input.Mui-focused': {
                            '& fieldset': {
                                fontFamily: 'Poppins',
                                '@media (max-width: 600px)': {
                                    height: '33px',
                                    borderRadius: '10px',
                                    boxShadow: "0px 5px 10px #0000000A",
                                    border: '1px solid #9CA7B2',
                                    lineHeight: "0.9em !important",
                                    fontSize: "5px !important"
                                }
                            }
                        },
                        '& .MuiInputBase-colorPrimary.Mui-error': {
                            borderColor: 'blue',
                        },
                    }}
                />
            )}
        />
    )

}
