export const formatedTimeStringFromSeconds = (seconds: number) => {
    let _minutes = Math.floor(seconds / 60);
    let _seconds = seconds % 60;
    let minuteString = _minutes < 10 ? `0${_minutes.toString()}` : _minutes.toString();
    let secondString = _seconds < 10 ? `0${_seconds.toString()}` : _seconds.toString();
    return minuteString + ' : ' + secondString;
}

export const getSecondsSince = (sinceTime: Date) => {
    const now = new Date();
    const startTimeInZone = new Date(sinceTime);
    const diff = now.getTime() - startTimeInZone.getTime();
    const diffSeconds = Math.round(diff / 1000);
    return diffSeconds;
}

export const getSecondsRemaining = (lastUpdate: number, seconds: number) => {
    let _newUpdate = new Date().getTime();

    let secondsUpdate = (lastUpdate === 0) ? 1 : Math.floor((_newUpdate - lastUpdate) / 1000);
    secondsUpdate = secondsUpdate <= 0 ? 1 : secondsUpdate;

    let _seconds = seconds - secondsUpdate;
    _seconds = _seconds < 0 ? 0 : _seconds;

    return {
        lastUpdate: _newUpdate,
        seconds: _seconds
    }
}