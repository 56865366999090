import { Box, styled, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AppToolbar } from "../_components/toolbar/app.toolbar";
import TrustpilotWidget from "../_components/trustpilot/trustBox.widget";
import { BACKGROUND_COLOR } from "../_const/color.const";

const drawerWidth = 240;

const useStyles = {

    appScreen: {
        display: 'flex',
        backgroundColor: "#e6e6e6",
        height: "100%",
        overflow: "auto !important",
    },
    main: {

        marginTop: "40px"
    },
    root: {
    },
    mainBackground: {},
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        borderTop: '1px solid #e6e6e6',
        zIndex: 9999
    },
    toolbar: {},
};

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    width: 'calc(100% - 500px)',
    maxWidth: "calc(100%)",
    paddingTop: theme.spacing(10),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));


export function AppScreen() {
    const [isOpen, setIsOpen] = useState(true);
    const isMobile = useMediaQuery('(max-width:600px)');

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div style={useStyles.appScreen} id="app-screen">

            <AppToolbar toggleSidebar={toggleSidebar} isOpen={isOpen}></AppToolbar>

            <Main sx={{
                backgroundColor: {
                    xs: "#fff", sm: BACKGROUND_COLOR
                }
            }} open={isOpen}>

                {!isMobile &&
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        minHeight="90%"
                    >
                        <Outlet></Outlet>
                    </Box>
                }
                {isMobile &&
                    <Box
                        width="100%"
                        margin="25px 0 25px 0"
                    >
                        <Outlet></Outlet>
                    </Box>
                }

                <Box
                    sx={{
                        p: 4,
                        backgroundColor: "#FFF",
                        boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)"
                    }}
                    component="footer"
                >

                    <TrustpilotWidget />
                </Box>

            </Main>
        </div >
    );
}