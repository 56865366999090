const local = {
    API: {
        AUTH: 'https://localhost:44396/api',
        DATA: 'https://localhost:44396/api'
    },
    STRIPE_KEY: "pk_test_51IT288GjAUflTTJSmc4ZVzou5VUuyGhOIZjQDqCpg8J8YR7XnbSFMbW7ka9hhowyg5DrzXQYJkuq0yrHtkutgSER00MMU5v1Cn"
}

const dev = {
    API: {
        AUTH: 'https://localhost:44396/api',
        DATA: 'https://localhost:44396/api'
    },
    STRIPE_KEY: "pk_test_51IT288GjAUflTTJSmc4ZVzou5VUuyGhOIZjQDqCpg8J8YR7XnbSFMbW7ka9hhowyg5DrzXQYJkuq0yrHtkutgSER00MMU5v1Cn"
}

const uat = {
    API: {
        AUTH: 'https://fabs-api-uat.azurewebsites.net/api',
        DATA: 'https://fabs-api-uat.azurewebsites.net/api'
    },
    STRIPE_KEY: "pk_test_51IT288GjAUflTTJSmc4ZVzou5VUuyGhOIZjQDqCpg8J8YR7XnbSFMbW7ka9hhowyg5DrzXQYJkuq0yrHtkutgSER00MMU5v1Cn"
}

const prod = {
    API: {
        AUTH: 'https://fabs-api.azurewebsites.net/api',
        DATA: 'https://fabs-api.azurewebsites.net/api'
    },
    STRIPE_KEY: "pk_live_51IT288GjAUflTTJS57dm2h6Vgy3xGgG3cmpBcKJWuqFA60RY0Dv8ScnMysqfD6fAALLTRH6QaWYPOqNJrkUYWkol00GuRbqARR"
}

const config = process.env.REACT_APP_STAGE === 'prod' ? prod
    : process.env.REACT_APP_STAGE === 'uat' ? uat
        : process.env.REACT_APP_STAGE === 'dev' ? dev
            : local;

const API_URL = config.API.DATA;
const AUTH_URL = config.API.AUTH;

export { API_URL, AUTH_URL };
export default config;
