import { Icon, useMediaQuery } from "@mui/material";
import { useState, useEffect, CSSProperties } from "react";
import { PRIMARY_COLOR, SECONDARY_COLOR, SUCCESS_COLOR, DANGER_COLOR, INFO_COLOR, WARNING_COLOR } from "../../_const/color.const";


export const useButtonStyles = (
    width: string,
    style?: 'slanted' | 'default' | 'pullPlanningModule' | 'pullPlanningModuleMiniY'
) => ({
    mainContainer: {
    },
    compact: {
        paddingLeft: "0px",
        paddingRight: "0px"
    },
    button: {
        borderRadius: "0px",
        color: "white",
        textTransform: "uppercase",
        width: `${width}`,
        marginLeft: style == 'slanted' ? "-2px !important" : "0px !important",
        marginBottom: style == 'slanted' ? "-10px !important" : "0px !important",
        marginTop: style == 'slanted' ? "-6px" : "0px",
        minHeight: style === "pullPlanningModuleMiniY" ? "50%" : style === "pullPlanningModule" ? "30px" : style == 'slanted' ? "35px" : "38px",
        float: style === "pullPlanningModuleMiniY" ? "left" : "none",
        maxHeight: "75px",
        padding: style === "pullPlanningModule" || style === "pullPlanningModuleMiniY" ? "0px" : "auto",
        "&:hover": {
            color: "white",
            boxShadow: "none",
            cursor: "pointer"
        },
        "& .material-icons": {
            marginBottom: "0px"
        },
        "&:disabled, &.disabled": {
            backgroundColor: "silver !important",
            color: "black !important",
            cursor: "auto !important"
        },
    },
    cellButton: {
        marginTop: "0px",
        height: "100%"
    },

    primaryButton: {
        backgroundColor: PRIMARY_COLOR[0],
        "&:hover,&:focus": {
            backgroundColor: PRIMARY_COLOR[0]
        }
    },

    secondaryButton: {
        backgroundColor: SECONDARY_COLOR[0],
        "&:hover,&:focus": {
            backgroundColor: SECONDARY_COLOR[1]
        }
    },

    activeButton: {
    },

    successsButton: {
        backgroundColor: SUCCESS_COLOR[0],
        "&:hover,&:focus": {
            backgroundColor: SUCCESS_COLOR[1]
        }
    },

    dangerButton: {
        backgroundColor: DANGER_COLOR[0],
        "&:hover,&:focus": {
            backgroundColor: DANGER_COLOR[1]
        }
    },

    infoButton: {
        backgroundColor: INFO_COLOR[0],
        "&:hover,&:focus": {
            backgroundColor: INFO_COLOR[1]
        }
    },

    warningButton: {
        backgroundColor: WARNING_COLOR[0],
        "&:hover,&:focus": {
            backgroundColor: WARNING_COLOR[1]
        }
    },

    defaultButton: {
        backgroundColor: '#e0e0e0',
        color: "black",
        "&:hover,&:focus": {
            color: "black",
            backgroundColor: '#d5d5d5'
        }
    },

    fairstone: {
        fontFamily: "Poppins, sans-serif",
        borderRadius: '15px',
        background: "transparent linear-gradient(270deg, #3BC1CD 0%, #33ADBC 100%) 0% 0% no-repeat padding-box",
        "&:hover,&:focus": {
            backgroundColor: PRIMARY_COLOR[1]
        },
        fontSize: "22px",
        fontWeight: "600",
        letterSpacing: "0px",
        textTransform: "none",
        height: "47px",
        width: "125px",
        boxShadow: "0px 3px 10px #0000001A", 
        opacity: 1,
        "@media (max-width: 600px)": {
            width: "95px",
        },
    },

    fairstoneApplyButton: {
        fontFamily: "Poppins, sans-serif",
        borderRadius: '15px',
        background: "transparent linear-gradient(270deg, #3BC1CD 0%, #33ADBC 100%) 0% 0% no-repeat padding-box",
        "&:hover,&:focus": {
            backgroundColor: PRIMARY_COLOR[1]
        },
        fontSize: "15px",
        fontWeight: "600",
        letterSpacing: "0px",
        textTransform: "none",
        height: "47px",
        width: "120px",
        boxShadow: "0px 3px 10px #0000001A", 
    },

    fairstoneBackButton: {
        fontFamily: "Poppins, sans-serif",
        borderRadius: '15px',
        background: "transparent linear-gradient(270deg, #959494 0%, #A3A2A2 85%, #A5A4A4 100%) 0% 0% no-repeat padding-box",
        "&:hover,&:focus": {
            backgroundColor: PRIMARY_COLOR[1]
        },
        fontSize: "22px",
        fontWeight: "600",
        letterSpacing: "0px",
        textTransform: "none",
        height: "47px",
        width: "125px",
        boxShadow: "0px 3px 10px #0000001A",
        textAlign: "center",
        "@media (max-width: 600px)": {
            width: "95px !important",
        }
    },

    fairstoneMobile: {
        fontFamily: "Poppins, sans-serif",
        borderRadius: '15px',
        background: "transparent linear-gradient(270deg, #3BC1CD 0%, #33ADBC 100%) 0% 0% no-repeat padding-box",
        "&:hover,&:focus": {
            backgroundColor: PRIMARY_COLOR[1]
        },
        fontSize: "15px",
        fontWeight: "600",
        letterSpacing: "0px",
        textTransform: "none",
        height: "37px",
        width: "95px",
        boxShadow: "0px 3px 10px #0000001A",
        opacity: 1,
    },

    fairstoneMobileBackButton: {
        fontFamily: "Poppins, sans-serif",
        borderRadius: '15px',
        background: "transparent linear-gradient(270deg, #959494 0%, #A3A2A2 85%, #A5A4A4 100%) 0% 0% no-repeat padding-box",
        "&:hover,&:focus": {
            backgroundColor: PRIMARY_COLOR[1]
        },
        //fontSize: "15px",
        fontWeight: "600",
        letterSpacing: "0px",
        textTransform: "none",
        height: "37px",
        width: "95px",
        boxShadow: "0px 3px 10px #0000001A",
        textAlign: "center",
    },

    fairstoneMobileApplyButton: {
        fontFamily: "Poppins, sans-serif",
        borderRadius: '15px',
        background: "transparent linear-gradient(270deg, #3BC1CD 0%, #33ADBC 100%) 0% 0% no-repeat padding-box",
        "&:hover,&:focus": {
            backgroundColor: PRIMARY_COLOR[1]
        },
        //fontSize: "15px",
        fontWeight: "600",
        letterSpacing: "0px",
        textTransform: "none",
        height: "37px",
        width: "95px",
        boxShadow: "0px 3px 10px #0000001A",
    },

    fairstoneConfirmButton: {
        fontFamily: "Poppins, sans-serif",
        borderRadius: '15px',
        background: "transparent linear-gradient(270deg, #3BC1CD 0%, #33ADBC 100%) 0% 0% no-repeat padding-box",
        "&:hover,&:focus": {
            backgroundColor: PRIMARY_COLOR[1]
        },
        //fontSize: "15px",
        fontWeight: "600",
        letterSpacing: "0px",
        textTransform: "none",
        height: "57px",
        width: "150px",
        boxShadow: "0px 3px 10px #0000001A",
    },

    buttonSlanted: {
        "-ms-transform": "skewX(-45deg)",
        "-webkit-transform": "skewX(-45deg)",
        transform: "skewX(-45deg)"
    },

    buttonSlantedContent: {
        "-ms-transform": "skewX(45deg)",
        "-webkit-transform": "skewX(45deg)",
        "transform": "skewX(45deg)",
        "display": "inline-block",
        "& *": {
            "-ms-transform": "skewX(45deg)",
            "-webkit-transform": "skewX(45deg)",
            "transform": "skewX(45deg)",
        },
        "& span": {
            "-ms-transform": "skewX(0deg)",
            "-webkit-transform": "skewX(0deg)",
            "transform": "skewX(0deg)",
        },
    },

    buttonFixedwidth: {
        width: "100px"
    },
    disabled: {
        fontFamily: "Poppins, sans-serif",
        borderRadius: '15px',
        backgroundColor: '#EBEBEB',
        color: '#A7A7A7',
        cursor: 'auto',
        fontSize: "18px",
        fontWeight: "bold",
        letterSpacing: "1.5px",
        textTransform: "none",
        height: "47px",
        width: "125px",
    },
    disabledMobile: {
        fontFamily: "Poppins, sans-serif",
        borderRadius: '15px',
        backgroundColor: '#EBEBEB',
        color: '#A7A7A7',
        cursor: 'auto',
        fontSize: "15px",
        fontWeight: "bold",
        letterSpacing: "1.5px",
        textTransform: "none",
        height: "37px",
        width: "95px",
    },

});

export const PrimaryButton = (params: {
    children: React.ReactNode,
    styles?: CSSProperties | undefined,
    variant: 'primary' | 'secondary' | 'active' | 'success' | 'danger' | 'info' | 'warning' | 'save' | 'remove' |
            'default' | 'fairstone' | 'fairstoneBackButton' | 'fairstoneApplyButton' | 'fairstoneMobile' | 'fairstoneMobileBackButton' | 'fairstoneMobileApplyButton' | 'fairstoneConfirmButton'
    type?: 'button' | 'submit',
    style?: 'slanted' | 'default' | 'pullPlanningModule' | 'pullPlanningModuleMiniY',
    compact?: boolean,
    cellButton?: boolean,
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    disabled?: boolean,
    isSaving?: boolean,
    isLoading?: boolean,
    isSubmittingPayment?: boolean,
    width?: string,
    title?: string,
    icon?: string,
    bgColor?: string
}) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    const buttonClasses = useButtonStyles(params.width || "100%", params.style || "default");

    let buttonClass: any = {};

    switch (params.variant) {
        case ("default"):
            buttonClass = buttonClasses.defaultButton; break;
        case ("primary"):
        case ("save"):
            buttonClass = buttonClasses.primaryButton; break;
        case ("secondary"): buttonClass = buttonClasses.secondaryButton; break;
        case ("active"): buttonClass = buttonClasses.activeButton; break;
        case ("success"): buttonClass = buttonClasses.successsButton; break;
        case ("danger"):
        case ("remove"):
            buttonClass = buttonClasses.dangerButton; break;
        case ("info"): buttonClass = buttonClasses.infoButton; break;
        case ("warning"): buttonClass = buttonClasses.warningButton; break;
        case ("fairstone"): buttonClass = buttonClasses.fairstone; break;
        case ("fairstoneBackButton"): buttonClass = buttonClasses.fairstoneBackButton; break;
        case ("fairstoneApplyButton"): buttonClass = buttonClasses.fairstoneApplyButton; break;
        case ("fairstoneMobile"): buttonClass = buttonClasses.fairstoneMobile; break;
        case ("fairstoneMobileBackButton"): buttonClass = buttonClasses.fairstoneMobileBackButton; break;
        case ("fairstoneMobileApplyButton"): buttonClass = buttonClasses.fairstoneMobileApplyButton; break;
        case ("fairstoneConfirmButton"): buttonClass = buttonClasses.fairstoneConfirmButton; break;

    }

    const _icon = params.icon ? <Icon>{params.icon}</Icon> : params.variant === 'save' ? <Icon>check</Icon> : params.variant === 'remove' ? <Icon>clear</Icon> : <></>;

    return (
        <button
            type={params.type || 'button'}
            style={{ ...buttonClasses.button, ...(params.disabled ? null : buttonClass), ...(params.compact ? buttonClasses.compact : {}), ...(params.cellButton ? buttonClasses.cellButton : ""), ...params.styles, ...(params.disabled ? isMobile ? buttonClasses.disabledMobile : buttonClasses.disabled : null) }}
            disabled={params.disabled}
            className={`btn ${params.variant} ${params.disabled} ${params.style === 'slanted' ? "buttonSlanted" : ""} `}
            onClick={params.onClick}
            title={params.title}
        >

            <span className={` ${params.style === 'slanted' ? "buttonSlantedContent" : ""}`}>
                {(params.isSaving !== true && params.isLoading !== true && params.isSubmittingPayment !== true) &&
                    <>
                        {_icon}

                        <>{params.children}</ >
                    </>
                }

                {(params.isSaving === true) &&
                    <>Saving<LoadingDots /></>
                }

                {(params.isLoading === true) &&
                    <>Loading<LoadingDots /></>
                }

                {(params.isSubmittingPayment === true) &&
                    <>Submitting Payment<LoadingDots /></>
                }
            </span>
        </button>
    );
}

const LoadingDots = () => {
    const [loadingStatus, setLoadingStatus] = useState("..");

    useEffect(() => {
        const intervalId = setInterval(() => {
            setLoadingStatus(ls => ls.length >= 5 ? ".." : ls + ".");
        }, 400);

        return () => clearInterval(intervalId);
    }, []);

    return <>{`${loadingStatus}`}</>;
};