import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { PrimaryButton } from "../../../_components/button/primary.button";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useEffect, useState } from "react";


export const NavButtons = (params: {
    showBack: boolean,
    showNext: boolean,
    isNextComplete: boolean,
    onBack(): void,
    onNext(): void,
    isDisableAllNav: boolean,
    isSubmit: boolean
}) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            <Box width="100%" display="flex" flex="wrap" gap={2} justifyContent="space-between">
                {params.showBack &&
                    <PrimaryButton
                        disabled={!params.showBack || params.isDisableAllNav}
                        variant={isMobile ? "fairstoneMobileBackButton" : "fairstoneBackButton"}
                        onClick={params.onBack}>
                        <Box
                            display="flex"
                            gap={0}
                            justifyContent="space-around"
                            alignItems='center'>
                            <Box width="80%" display="flex" justifyContent="space-around">

                                <Box width="20%" display="flex" alignSelf="center" justifySelf="flex-start">
                                    <ArrowBackIosNewIcon fontSize={isMobile ? "small" : "medium"} />
                                </Box>
                                <Box width="80%">
                                    <Typography
                                        fontFamily="Poppins"
                                        fontSize={{ xs: "12px", sm: "14px", md: "18px" }}
                                        fontWeight="600"
                                        letterSpacing="1.5px"
                                        textTransform="none">
                                        Back
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </PrimaryButton>
                }
                {/*{params.showNext &&*/}
                <PrimaryButton
                    disabled={!params.showNext || params.isDisableAllNav}
                    variant={isMobile ? "fairstoneMobile" : "fairstone"}
                    type={params.isSubmit ? "submit" : "button"}
                    onClick={params.onNext}>
                    <Box
                        display="flex"
                        gap={0}
                        justifyContent="space-around"
                        alignItems='center'>
                        {/*{!params.isNextComplete &&*/}
                        {/*    <Box width="80%" display="flex" justifyContent="center">*/}
                        {/*        <Typography*/}
                        {/*            fontSize={{ xs: "15px", md: "22px" }}*/}
                        {/*            fontWeight="600"*/}
                        {/*            letterSpacing="1.5px"*/}
                        {/*            textTransform="none" textAlign="center">*/}
                        {/*            Next*/}
                        {/*        </Typography>*/}
                        {/*    </Box>*/}
                        {/*}*/}
                        {/*{params.isNextComplete && <>Complete</>}*/}
                        {/*<Box width="20%" display="flex" alignSelf="center" justifySelf="flex-end">*/}
                        {/*    <ArrowForwardIosIcon fontSize={isMobile ? "small" : "medium"} />*/}
                        {/*</Box>*/}

                        <Box width={params.isNextComplete ? "100%" : "80%"} display="flex" justifyContent="space-around">
                            <Box width={params.isNextComplete ? "100%" : "80%"}>
                                <Typography
                                    fontFamily="Poppins"
                                    fontSize={{ xs: "12px", sm: "14px", md: "18px" }}
                                    fontWeight="600"
                                    letterSpacing="1.5px"
                                    textTransform="none" textAlign="center">
                                    {params.isNextComplete && params.isDisableAllNav ? "" : params.isNextComplete ? "Complete" : "Next"}
                                    {(params.isNextComplete && params.isDisableAllNav) &&
                                       <LoadingDots />
                                    }
                                </Typography>
                            </Box>
                            {!params.isNextComplete &&
                                <Box width="20%" display="flex" alignSelf="center" justifySelf="flex-end">
                                    <ArrowForwardIosIcon fontSize={isMobile ? "small" : "medium"} />
                                </Box>
                            }
                        </Box>

                    </Box>
                </PrimaryButton>
                {/*}*/}


            </Box>
        </>);
}

const LoadingDots = () => {
    const [loadingStatus, setLoadingStatus] = useState("..");

    useEffect(() => {
        const intervalId = setInterval(() => {
            setLoadingStatus(ls => ls.length >= 5 ? ".." : ls + ".");
        }, 400);

        return () => clearInterval(intervalId);
    }, []);

    return <>{`${loadingStatus}`}</>;
};
