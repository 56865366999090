import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { PRIMARY_COLOR, WHITE_COLOR } from '../../_const/color.const';

const CssBox = styled(Box)({
    '&.MuiBox-root': {
        background: '#505050 0% 0% no-repeat padding-box',
        borderRadius: "20px",
        border: "1px solid #707070",
        color: WHITE_COLOR,
        paddingBottom: '10px',
        margin: "20px auto",
    },
});

export default function ServiceCardBox(props: {
    children: React.ReactNode,
}) {
    return (
        <CssBox 
            display="flex"
            gap={1}
            pt={2}
            pb={2}
            pr={1}
            width={{ xs: "270px", sm: "280px", md: "343px" }}
            height={{ xs: "70px", sm: "80px", md: "124px" }}
            justifyContent='space-between'
            alignItems="center"                      
        >
            {props.children}
        </CssBox>
    );
}