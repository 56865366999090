import { useEffect, useState } from "react";
import { TimerStatusEnum } from "../../../_common/enum/timerStatus.enum";
import { ITimerOptions } from "../../../_common/interface/timer/iTimerOptions";
import { formatedTimeStringFromSeconds, getSecondsRemaining } from "../../../_helpers/time.helper";
import { AppBar } from "@mui/material";
import { PRIMARY_COLOR } from "../../../_const/color.const";

export function JourneyTimer(params: {
    timerOptions: ITimerOptions,
    emitOnTimerExpire: () => void
}) {
    const [timerOptions, setTimerOptions] = useState({
        seconds: 0,
        resetCounter: 0,
        timerStatus: TimerStatusEnum.Paused,
        lastUpdate: 0
    });

    useEffect(() => {
        // console.log("pre reset", params.timerOptions.resetCounter, timerOptions.resetCounter, params.timerOptions);
        if (params.timerOptions.resetCounter === timerOptions.resetCounter && params.timerOptions.timerStatus === timerOptions.timerStatus) return;
        // console.log("post reset", params.timerOptions.resetCounter, timerOptions.resetCounter, params.timerOptions);

        setTimerOptions(options => {
            return {
                timerStatus: params.timerOptions.timerStatus,
                seconds: params.timerOptions.totalMainSecondsCountdown > 0 ? params.timerOptions.totalMainSecondsCountdown : 0,
                resetCounter: params.timerOptions.resetCounter,
                lastUpdate: 0
            }
        });

    }, [params.timerOptions.timerStatus, params.timerOptions.resetCounter]);

    useEffect(() => {
        if (timerOptions.seconds <= 0 || (timerOptions.timerStatus & TimerStatusEnum.IsStopped)) return;
        let _trigger = tick;
        let _interval = 1000;

        const intervalId = setInterval(_trigger, _interval);

        return () => clearInterval(intervalId);

    }, [timerOptions.timerStatus]);

    useEffect(() => {
        if (timerOptions.seconds <= 0 && (timerOptions.timerStatus & TimerStatusEnum.IsRunning)) {
            params.emitOnTimerExpire();
        }

    }, [timerOptions.seconds]);

    function tick() {
        if (timerOptions.seconds > 0) {
            setTimerOptions(s => {

                let _time = getSecondsRemaining(s.lastUpdate, s.seconds);

                return {
                    ...s,
                    seconds: _time.seconds,
                    lastUpdate: _time.lastUpdate
                }
            });
        }
    }

    return(<>{formatedTimeStringFromSeconds(timerOptions.seconds)}</>);
}