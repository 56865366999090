import { Box, Chip, CircularProgress, FormControl, Grid, Paper, makeStyles, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";

import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs, locale } from "dayjs";
import { getDate, getDateString, getDateWithDateNameString, getDaysAheadDateString, getDaysAheadFromTodayDayJs, getTodayDateString } from "../../../_helpers/dateTime.helper";
import { PRIMARY_COLOR } from "../../../_const/color.const";
import { appointmentService } from "../../../_services/appointment.service";
import { IAppointmentTimeManage } from "../../../_common/interface/appointment/iAppointmentTimeManage";
import { handleErrorResponse } from "../../../_helpers/handleResponse.helper";
import { ICustomerAppointmentManage } from "../../../_common/interface/customerAppointment/iCustomerAppointmentManage";
import { businessHolidaysService } from "../../../_services/businessHoliday.service";
import StyledStaticDatePicker from "../../../_components/staticDatePicker/staticDatePicker";

export const AppointmentPickerStep = (params: {
    data: ICustomerAppointmentManage,
    serviceType: string,
    serviceId: number,
    appointmentMinDaysInAdvance: number,
    appointmentMaxDaysInAdvance: number,
    onChange(event: any): void,
    onAppointmentTimeSelected(value: string): void
}) => {
    const [isLoading, setLoading] = useState(true);
    const [appointmentDate, setAppointmentDate] = useState(dayjs(getDaysAheadDateString(2)));
    const [minDate, setMinDate] = useState(dayjs(getDaysAheadDateString(params.appointmentMinDaysInAdvance)));
    const [appointmentTimes, setAppointmentTimes] = useState<Array<IAppointmentTimeManage>>([]);
    const [disabledDatesList, setDisabledDatesList] = useState<Array<string>>([]);

    useEffect(() => {
        fetchAppointmentTimes(new Date(), false);
        fetchBusinessHolidays();
    }, []);

    function fetchBusinessHolidays() {
        return businessHolidaysService.getBusinessHolidaysForDaysAhead(params.appointmentMaxDaysInAdvance)
            .then(
                days => {
                    setDisabledDatesList(days);
                }, handleErrorResponse)
            .finally(() => {

            });
    }

    function fetchAppointmentTimes(date: any, isAppointmentDateChange: boolean) {

        params.onAppointmentTimeSelected('false');

        let query = {
            appointmentDate: '',
            serviceId: params.serviceId,
            incomeBandId: params.data.incomeBandId,
            mortgageTypeId: params.data.mortgageTypeId,
            mortgageAmountRequired: params.data.mortgageAmountRequired
        }

        if (isAppointmentDateChange === true && date) {
            let _appointmentDate = new Date(date.year(), date.month(), date.date(), 0, 0, 0);
            query.appointmentDate = getDateString(_appointmentDate);
        }

        setLoading(true);
        return appointmentService.getAllAppointmentTimesForDay(query)
            .then(
                times => {
                    if (params.data.appointmentDate) {

                        times.forEach((x: any) => {
                            if (x.appointmentTime === params.data.appointmentDate) {
                                x.isSelected = true;
                                x.isAvailable = true;
                                params.onAppointmentTimeSelected('true');
                            }
                        });
                    }

                    if (times.length > 0) {
                        if (isAppointmentDateChange === false) { // Initial load of page
                            setMinDate(dayjs(getDateString(times[0].appointmentTime)));
                        }

                        let _date = dayjs(getDateString(times[0].appointmentTime));
                        setAppointmentDate(_date);

                        setAppointmentTimes(times);
                    } else {
                        setMinDate(getDaysAheadFromTodayDayJs(params.appointmentMinDaysInAdvance + 1))
                        setAppointmentTimes([]);
                    }

                    setLoading(false);
                }, handleErrorResponse)
            .finally(() => {
                // setLoading(false);
            });
    }

    function handleDateChange(date: any) {
        setAppointmentDate(date);
        fetchAppointmentTimes(date, true);
    }

    function handleAppointmentTimeClick(appTimeId: number) {

        // Visually represent this selection      
        let _appointmentTimes: IAppointmentTimeManage[] = appointmentTimes;

        // Find the selected appointment time
        // If not available then do nothing
        let selectedAppointmentTime = _appointmentTimes.find(x => x.id === appTimeId);
        if (selectedAppointmentTime && selectedAppointmentTime.isAvailable === false) return;

        // Set the selected appointment time as selected
        for (let x of _appointmentTimes) {
            if (x.id === appTimeId)
                x.isSelected = true;
            else
                x.isSelected = false;
        }

        setAppointmentTimes(_appointmentTimes);

        params.onChange({ appointmentDate: appointmentTimes[appTimeId - 1].appointmentTime, name: 'appointmentDate' });
    }

    function getAppointmentChip(appt: any) {
        const borderStyle = '2px solid ' + PRIMARY_COLOR[5];
        const labelStyle = appt.isAvailable
            ? appt.isSelected
                ? { background: PRIMARY_COLOR[5], color: 'white', borderRadius: '15px' }
                : { border: borderStyle, background: 'white', borderRadius: '15px' }
            : { textDecoration: 'line-through', background: "#BEBEBE", borderRadius: '15px' };

        return (
            <Box>
                <Chip
                    key={appt.id}
                    label={appt.appointmentTimeStr}
                    style={labelStyle}
                    sx={{
                        height: { xs: '36px', md: '58px' },
                        width: { xs: '91px', sm: '140px', md: '122px' },
                        fontSize: '20px',
                        fontFamily: 'Poppins',
                        color: PRIMARY_COLOR[5],
                        borderRadius: { xs: '10px !important', md: '15px' },
                        marginBottom: "0px"
                    }}
                    onClick={(e: any) => handleAppointmentTimeClick(appt.id)} />
            </Box>
        );
    }

    function isDateDisabled(dateToCheck: any, disabledDatesList: any) {
        return disabledDatesList?.some((disabledDate: any) =>
            dayjs(disabledDate).isSame(dateToCheck, 'day')
        );
    }

    function shouldDisableDate(date: any) {
        return isDateDisabled(date, disabledDatesList);
    }

    dayjs.Ls.en.weekStart = 1; /*week will start from Monday*/

    return (
        <>
            <Box
                width="100%" display='flex' marginRight={{ xs: "0px", md: "48px" }} marginLeft={{ xs: "0px", md: "30px" }} gap={1} p={0} justifyContent="center" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
            >

                <Box width={{ xs: "100%", sm: "100%", md: "100%", lg: "80%", xl: "90%" }} mt={2} display="flex" justifyContent="center">
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es"}>
                        <StyledStaticDatePicker
                            disablePast={true}
                            value={appointmentDate}
                            onChange={(date) => handleDateChange(date)}
                            minDate={minDate}
                            maxDate={getDaysAheadFromTodayDayJs(params.appointmentMaxDaysInAdvance)}
                            sx={{ borderRadius: '40px!important', fontSize: "5em !important" }}
                            shouldDisableDate={shouldDisableDate}
                            dayOfTheWeekFormatter={(_day, weekday: any) => `${weekday.format('ddd')}`} />
                    </LocalizationProvider>
                </Box>

                <Box display="flex" flexDirection="column"
                    justifyContent={{ xs: "center", md: "flex-start" }}
                    width={{ xs: "100%", sm: "100%", md: "100%", lg: "100%", xl: "100%" }}
                    margin="0px auto"
                >
                    <Box
                        mb={{ xs: 1, md: 4 }}
                        marginRight={{ xs: "15px", md: "60px" }}
                        marginLeft={{ xs: "15px", md: "auto" }}
                        display="flex"
                        justifyContent="center"
                    >
                        <Typography
                            fontSize={{ xs: "14px", md: "16px" }}
                            marginTop={{ xs: "5px", sm: "10px", md: "40px" }}
                            marginBottom={{ xs: "0px", sm: "20px", lg: "0px" }}
                            fontFamily="Poppins, sans-serif"
                            fontWeight="300"
                        >
                            Here you can find a list of available time slots for <span style={{ color: "#2E99A6", fontWeight: "bold" }}> {params.serviceType} Consultation on </span>
                            <span style={{ color: "#2E99A6", fontWeight: "bold", fontSize: "20px" }}>{getDateWithDateNameString(appointmentDate.toDate())}:</span>
                        </Typography>
                    </Box>
                    {isLoading &&
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                color: PRIMARY_COLOR[0],
                            }}>
                                <Typography
                                    textAlign={{ xs: "center", md: "left" }}
                                    fontSize={{ xs: "14px", md: "18px" }}
                                    fontFamily="Poppins, sans-serif"
                                    mr={{ xs: "0px", md: "48px" }}
                                    mt={{ xs: "5px", md: "20px" }}
                                    mb={{ xs: "15px", md: "0px" }}
                                >
                                    Please wait, we are checking Appointment availability.
                                </Typography>
                            </Box>
                        </>
                    }
                    {!isLoading &&
                        <>
                            <Box display="flex" justifyContent={{ xs: "center", md: "flex-start" }}
                                flexDirection="row"
                                mb={{ xs: "15px", md: "0px" }}
                                mt={{ xs: "0px", md: "0px" }}
                                p={0} flexWrap="wrap"
                                flexBasis="33.333333%"
                                columnGap={{ xs: "15px", md: "9px" }}
                                rowGap="9px">
                                {appointmentTimes.length === 0 && !isLoading &&
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        color: PRIMARY_COLOR[0],
                                        fontSize: { xs: "16px", md: "1.3em" }
                                    }}>
                                        No appointments available.
                                    </Box>
                                }
                                {appointmentTimes && !isLoading && appointmentTimes.map((a, index) => (
                                    // Need <Fragment> to use key prop 
                                    <Fragment key={index}>{getAppointmentChip(a)}</Fragment>
                                ))}
                            </Box>
                            <Box
                                mb={{ xs: 1, md: 4 }}
                                marginRight={{ xs: "15px", md: "60px" }}
                                marginLeft={{ xs: "15px", md: "auto" }}
                                display="flex"
                                justifyContent="center"
                            >
                                <Typography
                                    fontSize={{ xs: "10px", md: "12px" }}
                                    marginTop={{ xs: "5px", sm: "5px", md: "10px" }}
                                    marginBottom={{ xs: "0px", sm: "20px", lg: "0px" }}
                                    fontFamily="Poppins, sans-serif"
                                    fontWeight="300"
                                >
                                    <span style={{ color: "#2E99A6", fontWeight: "bold", fontStyle: "italic" }}>* If appointment is not available on the date you selected, the next available date will be displayed.</span>
                                </Typography>
                            </Box>
                        </>
                    }
                </Box>
            </Box>
        </>);
}