import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useNavigate, useParams } from 'react-router-dom';
import { AppointmentStatusEnum } from '../../_common/enum/appointmentStatusEnum';
import { PrimaryButton } from '../../_components/button/primary.button';
import { toastr } from '../../_components/toastr/toastr';
import { PRIMARY_COLOR } from '../../_const/color.const';
import { appointmentService } from '../../_services/appointment.service';
import { AppointmentCancellationWarning } from './components/appointmentCancellationWarning';
import { AppointmentCancelled } from './components/appointmentCancelled';

export function AppointmentManage() {

    let navigate = useNavigate();

    const { token } = useParams<{ token: any }>();
    const [data, setData] = useState<any>(null);
    const [cancellationReason, setCancellationReason] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);
    const [canCancel, setCanCancel] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (data && data?.appointmentStatusId === AppointmentStatusEnum.Cancelled) {
            setIsCancelled(true);
        }

        if (data && data?.appointmentDate) {
            // Calculate the difference in milliseconds between current time and appointment time
            let _appointmentDate = new Date(data.appointmentDate);
            const timeDifference = _appointmentDate.getTime() - new Date().getTime();

            // Calculate the number of hours remaining until the appointment
            const hoursRemaining = timeDifference / (1000 * 3600);

            // Update the canCancel state based on the 48-hour restriction
            setCanCancel(hoursRemaining > 48 && data.isActive === true);
        }
    }, [data]);

    function fetchData() {
        setLoading(true);

        return appointmentService
            .getCustomerAppointment(token)
            .then(
                (json) => {
                    setData(json);
                }
            )
            .finally(() => {
                setLoading(false);
            });
    }

    function submit(e: any) {
        e.preventDefault();
        setIsSaving(true);

        return appointmentService
            .cancelCustomerAppointment(data.id, cancellationReason)
            .then(
                () => {
                    setIsCancelled(true);
                    setCanCancel(false);
                },
                (e) => toastr.error(e)
            )
            .finally(() => setIsSaving(false));
    }

    return (
        <>
            <ValidatorForm
                className="form-side-nav"
                onSubmit={submit}
                instantValidate>

                <Box display="flex" justifyContent="center">
                    {
                        (!canCancel && !isCancelled) && <AppointmentCancellationWarning />
                    }

                    {canCancel &&
                        <Box display="flex" flexDirection="column" gap={5} marginBottom={{xs: "30px", md: "100px"}}>
                            <Box>
                                <Typography color={PRIMARY_COLOR[5]} align="center" padding={1} fontSize={{ xs: "16px", md: "20px" }} fontFamily="Poppins">
                                    To reschedule appointment please cancel this appointment and book a new appointment
                                </Typography>
                            </Box>

                            <Box sx={{ flexGrow: 1, padding: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box>
                                            <Typography align="left" fontFamily="Poppins">Cancellation reason ? <span style={{
                                                color: PRIMARY_COLOR[5]
                                            }}>*</span></Typography>
                                        </Box>
                                        <TextValidator
                                            key="cancellationReason"
                                            name="cancellationReason"
                                            validators={[`required`]}
                                            errorMessages={['This field is required']}
                                            fullWidth
                                            value={cancellationReason}
                                            onChange={(e: any) => { setCancellationReason(() => { return e.target.value }) }}
                                            InputProps={{ sx: { borderRadius: 4 } }}
                                            multiline
                                            rows={4}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box display="flex" justifyContent="center">
                                <PrimaryButton
                                    variant="fairstoneConfirmButton"
                                    type="submit"
                                >
                                    <Box width="100%" display="flex" justifyContent="space-around">
                                        <Typography align="center"
                                            fontSize={{ xs: "14px", sm: "16px", md: "16px" }}
                                            fontWeight="600"
                                            letterSpacing="1.5px"
                                            textTransform="none">
                                            Confirm Cancellation
                                        </Typography>
                                    </Box>
                                </PrimaryButton>
                            </Box>

                        </Box>
                    }

                    {
                        isCancelled && <AppointmentCancelled />
                    }
                </Box>

            </ValidatorForm>
        </>
    );
}