import miniToastr from 'mini-toastr';

const defaultConfig = {
  timeout: 5000
}
const toastr = miniToastr.init(defaultConfig);

toastr.setIcon('error', 'i', { 'class': 'fa fa-warning' })
toastr.setIcon('info', 'i', { 'class': 'fa fa-info-circle' })
toastr.setIcon('success', 'i', { 'class': 'fa fa-check-circle-o' })

export { toastr };