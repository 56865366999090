import dayjs from "dayjs";

export function getDate(date: Date) {
    var _date = new Date(date);

    let dd = _date.getDate();
    let d = dd < 10 ? "0" + dd : dd;

    let mm = (_date.getMonth() + 1);
    let m = mm < 10 ? "0" + mm : mm;

    var mimi = _date.getMinutes();
    let mi = mimi < 10 ? "0" + mimi : mimi;

    var hh = _date.getHours();
    let h = hh < 10 ? "0" + hh : hh;

    return `${d}/${m}/${_date.getFullYear()}`;
}

export function getDateTime(date: Date) {
    var _date = new Date(date);

    let dd = _date.getDate();
    let d = dd < 10 ? "0" + dd : dd;

    let mm = (_date.getMonth() + 1);
    let m = mm < 10 ? "0" + mm : mm;

    var mimi = _date.getMinutes();
    let mi = mimi < 10 ? "0" + mimi : mimi;

    var hh = _date.getHours();
    let h = hh < 10 ? "0" + hh : hh;

    return `${d}/${m}/${_date.getFullYear()} ${hh}:${mm}`;
}

export function getToday() {
    let newDate = new Date();
    return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
}

export const getTodayDateString = () => {
    let d = new Date();
    d.setDate(d.getDate());
    return getDateString(d);
}

export function getDaysAheadDateString(days: number) {
    let d = new Date();
    d.setDate(d.getDate() + days);
    return getDateString(d);
}

export function getDaysAheadFromTodayDayJs(days: number) {
    return dayjs().add(days, 'day');
}

export const getDateString = (date: Date | null) => {
    if(date === null || date === undefined) 
    return '';

    let _date = new Date(date);

    let yy = _date.getFullYear();
    let mm = _date.getMonth() < 9 ? "0" + (_date.getMonth() + 1) : (_date.getMonth() + 1);
    let dd = _date.getDate() < 10 ? "0" + _date.getDate() : _date.getDate();
    return `${yy}-${mm}-${dd}`;
}

export function getLongDateString(date: Date | null): string {
    if(date === null || date === undefined) 
        return '';
    
    let _date = new Date(date);
    const day = _date.getDate();
    const month = _date.toLocaleString('default', { month: 'short' });
    const year = _date.getFullYear();
    const hours = ('0' + _date.getHours()).slice(-2);
    const minutes = ('0' + _date.getMinutes()).slice(-2);

    let daySuffix = 'th';
    if (day === 1 || day === 21 || day === 31) {
      daySuffix = 'st';
    } else if (day === 2 || day === 22) {
      daySuffix = 'nd';
    } else if (day === 3 || day === 23) {
      daySuffix = 'rd';
    }

    const formattedDate = `${day}${daySuffix} ${month} ${year} ${hours}:${minutes}`;

    return formattedDate;
}

export function getDateWithDateNameString(date: Date | null): string {
    if (date === null || date === undefined)
        return '';

    let _date = new Date(date);
    const dayOfWeek = _date.toLocaleString('default', { weekday: 'long' }); // Get the full name of the day (e.g., Monday)
    const day = _date.getDate();
    const month = _date.toLocaleString('default', { month: 'short' });
    const year = _date.getFullYear();

    // Determine the suffix for the day (st, nd, rd, th)
    let daySuffix = 'th';
    if (day === 1 || day === 21 || day === 31) {
        daySuffix = 'st';
    } else if (day === 2 || day === 22) {
        daySuffix = 'nd';
    } else if (day === 3 || day === 23) {
        daySuffix = 'rd';
    }

    // Return the formatted date including the day of the week
    const formattedDate = `${dayOfWeek}, ${day}${daySuffix} ${month} ${year}`;

    return formattedDate;
}

//export function getFormattedDateAndTime(dateInput: Date) {
//    let date = null;

//    // Check if the input is already a Date object
//    if (dateInput instanceof Date) {
//        date = dateInput;
//    } else if (typeof dateInput === 'string') {
//        date = new Date(dateInput);
//    }

//    if (!date || isNaN(date.getTime())) {
//        return null; // Return null if date is invalid
//    }

//    const day = date.getDate();
//    const dayWithSuffix = getDayWithSuffix(day);

//    const options = { day: 'numeric', month: 'long', year: 'numeric' } as const;
//    const formattedDate = date.toLocaleDateString('en-GB', options);
//    const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
//    return { formattedDate, time };
//}

//function getDayWithSuffix(day:number) {
//    if (day >= 11 && day <= 13) {
//        return day + "th";
//    }
//    switch (day % 10) {
//        case 1: return day + "st";
//        case 2: return day + "nd";
//        case 3: return day + "rd";
//        default: return day + "th";
//    }
//}

export function getFormattedDateAndTime(dateInput: Date) {
    let date = null;

    // Check if the input is already a Date object
    if (dateInput instanceof Date) {
        date = dateInput;
    } else if (typeof dateInput === 'string') {
        date = new Date(dateInput);
    }

    if (!date || isNaN(date.getTime())) {
        return null; // Return null if date is invalid
    }

    const options = { month: 'long', year: 'numeric' } as const;
    const monthYear = date.toLocaleDateString('en-GB', options);

    // Get the day and append the appropriate suffix
    const day = date.getDate();
    const dayWithSuffix = getDayWithSuffix(day);

    // Format time in 24-hour format
    const time = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false });

    return { monthYear, dayWithSuffix, time };
}

// Function to append the appropriate suffix to the day
function getDayWithSuffix(day: number) {
    if (day >= 11 && day <= 13) {
        return day + "th";
    }
    switch (day % 10) {
        case 1: return day + "st";
        case 2: return day + "nd";
        case 3: return day + "rd";
        default: return day + "th";
    }
}

