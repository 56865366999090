import { Box, Grid } from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { IPaymentManage, resetPaymentData } from "../../../_common/interface/payment/iPaymentManage";
import { useEffect, useState } from "react";
import { PrimaryButton } from "../../../_components/button/primary.button";
import { Appearance, loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { paymentService } from "../../../_services/payment.service";
import { CheckoutForm } from "../userJourneyComponents/checkoutForm";
import config from "../../../_config/config.const";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(config.STRIPE_KEY); 

export const PaymentStep = (params: {
    paymentAmount: number,
    discountCodeId: number,
    customerEmail: string,
    onSubmitPayment(): void
}) => {
    const [data, setData] = useState<IPaymentManage>(resetPaymentData);
    const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);

    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        paymentService
             //.createPaymentIntent(10.99, "ayordanov@hcssoftware.ie") //For testing
            .createPaymentIntent(params.paymentAmount, params.customerEmail)
            .then((data) => {
                setClientSecret(data.clientSecret)
            });
    }, []);

    const appearance: Appearance = {
        theme: 'stripe',
    };
    const options: StripeElementsOptions = {
        clientSecret,
        appearance,
    };

    function submit() {
        setIsSubmittingPayment(true);
        params.onSubmitPayment();
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={0} md={4}></Grid>
            <Grid item xs={12} md={4}>
                {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                        <CheckoutForm onConfirmPayment={submit} />
                    </Elements>
                )}
            </Grid>
            <Grid item xs={0} md={4}></Grid>
        </Grid>
    );
}