import * as React from 'react';
import { styled } from '@mui/material/styles';
import { StaticDatePicker } from '@mui/x-date-pickers';

const CssStaticDatePicker = styled(StaticDatePicker)({

    '& .MuiDateCalendar-root': {
        width: "100% !important",
        height: "100%vh!important",
        borderRadius: "20px",
        margin: '20px 10px',
        //boxShadow: "0px 20px 30px #2F2F2F22",
        paddingBottom: "30px",
        paddingLeft: "4px",
        paddingRight: "4px",
        "@media (max-width: 600px)": {
            margin: 0,
            padding: 0,
            width: "90% !important",
            height: "270px !important"
        },
    },
    '&.MuiPickersLayout-root': {
        boxShadow: "0px 20px 30px #2F2F2F22",
        marginLeft: "10px",
        marginRight: "10px",
        "@media (max-width: 600px)": {
            marginLeft: "10px",
            marginRight: "10px",
            borderRadius: "15px !important",
            height: "270px"

        },
        "@media (max-width: 360px)": {
            marginLeft: "0px",
            marginRight: "0px",
            borderRadius: "15px !important",
            height: "270px",
        },
    }, 
    '& .MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition': {
        minHeight: "500px",
        margin: "0px",
    },

    '& .MuiPickersLayout-contentWrapper': {
        margin: '10px',
        paddingRight: "20px",
        "@media (max-width: 600px)": {
            marginLeft: "15px !important",
            marginBottom: "0px",
        },

    },
    '& .MuiPickersDate-root': {
        width: "100%",
        height: "100%",
    },
    '& .MuiPickersDay-root': {
        fontWeight: "400",
        fontSize: "17px",
        fontFamily: "Poppins, sans-serif",
        "@media (max-width: 600px)": {
            height: "30px"
        },
    },
    '& .MuiButtonBase-root.MuiPickersDay-root': { /* actual dates */
        fontSize: "17px",
        fontFamily: "Poppins",
        color: "#171D35",
        margin: "2px",
        "@media (max-width: 600px)": {
            fontSize: "11px !important"
        },
    },
    '& .MuiDayCalendar-header': {
        gap: "15px",
        "@media (max-width: 600px)": {
            gap: "8px !important"
        },
        "@media (max-width: 375px)": {
            gap: "6px !important"
        },
    },
    '& .MuiDayCalendar-weekDayLabel': { /*weekday labels*/
        fontWeight: "600",
        fontSize: "16px",
        fontFamily: "Poppins",
        color: "#171D35",
        '& [aria-label="Sunday"]': {
            fontSize: "0px !important"
        },
        "@media (max-width: 600px)": {
            fontSize: "11px !important",
            height: "30px"
        },
    },
    '& .MuiDayCalendar-monthContainer': {
    },
    '& .MuiDayCalendar-weekContainer': {
        gap: "15px",
        "@media (max-width: 600px)": {
            gap: "9px !important",
            height: "30px"
        },
        "@media (max-width: 390px)": {
            gap: "6px !important",
            height: "30px"
        },
    },
    '& .MuiSvgIcon-root': {
        width: "34px",
        height: "24px",
        fontWeight: "bold",
        fontSize: "large"
    },
    '& .MuiPickersCalendarHeader-root': { /*month header style*/
        marginTop: "40px",
        "@media (max-width: 600px)": {
            marginTop: "10px !important"
        }
    },
    '& .MuiPickersCalendarHeader-labelContainer': { /*month header style*/
        fontSize: "21px",
        fontWeight: "600",
        fontFamily: "Poppins",
        margin: "20px auto",
        marginTop: "40px",
        marginBottom: "40px",
        "@media (max-width: 600px)": {
            fontSize: "14px"
        }
        
    },
    '& .MuiPickersFadeTransitionGroup-root': { /*actual month header font size*/
        fontWeight: "bold !important",
        fontSize: "25px !important",
        "@media (max-width: 600px)": {
            fontSize: "13px !important"
        },
    },
    ' button[aria-label="Previous month"]': { 
        float: "left",
        marginLeft: "0px",
        fontWeight: "bold"

    },
    ' button[aria-selected="true"] ': { /* selected date */
        color: "white !important",
        backgroundColor: "#3BC1CD"
    },
    '.Mui-disabled': {
        color: "lightgrey !important"
    }

});

export default function StyledStaticDatePicker(props: {
    disablePast?: boolean,
    value?: number | string | undefined | any,
    onChange?(date: any): void,
    minDate?: any,
    maxDate?: any,
    sx?: {},
    shouldDisableDate(date: any): any,
    dayOfTheWeekFormatter?(day: string, date: unknown): string,

}) {
    return (

        <CssStaticDatePicker
            disablePast={props.disablePast}
            value={props.value}
            onChange={props.onChange}
            minDate={props.minDate}
            maxDate={props.maxDate}
            sx={props.sx}
            shouldDisableDate={props.shouldDisableDate}
            dayOfWeekFormatter={props.dayOfTheWeekFormatter}
            views={['month', 'day']}
        />
    );
}

