import { API_URL } from "../_config/config.const";
import { handleResponse } from "../_helpers/handleResponse.helper";
import { getHeader } from "../_helpers/header.helper";

const API_NAME = "dropdown";

export const dropdownService = {
    fetchData
};

function fetchData(dataName: string, method: string = 'GET') {
    const requestOptions = { method, headers: getHeader() };
    const endpoint = `get${dataName}`;
    return fetch(`${API_URL}/${API_NAME}/${endpoint}/`, requestOptions).then(handleResponse);
}




