import { API_URL } from "../_config/config.const";
import { handleResponse } from "../_helpers/handleResponse.helper";
import { getHeader } from "../_helpers/header.helper";
import { getQuery } from "../_helpers/query.helper";

const API_NAME = "incomeBand";

export const incomeBandService = {
    getAll
};

function getAll() {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetAll`, requestOptions).then(handleResponse);
}