import { API_URL } from "../_config/config.const";
import { getBody } from "../_helpers/body.helper";
import { handleResponse } from "../_helpers/handleResponse.helper";
import { getHeader } from "../_helpers/header.helper";
import { getQuery } from "../_helpers/query.helper";

const API_NAME = "businessHolidays";

export const businessHolidaysService = {
    getBusinessHolidaysForDaysAhead
};

function getBusinessHolidaysForDaysAhead(days: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetBusinessHolidaysForDaysAhead/?daysAhead=${days}`, requestOptions).then(handleResponse);
}
