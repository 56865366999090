export interface ICustomerAppointmentManage {
    // Customer Fields
    customerId: number | null,
    firstName: string,
    lastName: string,
    email: string,
    dateOfBirth: string,
    address1: string | null,
    address2: string | null,
    address3: string | null,
    eircode: string | null,
    notes: string | null,
    occupation: string,
    mobilePhoneNo: string,
    incomeBandId: number | null,
    isPreviousCreditIssues: boolean,
    companyName: string | null,
    // Appointment Fields
    id: number | null,
    advisorId: number | null,
    appointmentDate: Date | null,
    appointmentStatusId: number | null,
    serviceId: number | null,
    durationId: number | null,
    isActive: boolean, 
    mortgageAmountRequired: number | null,
    mortgageTypeId: number | null,
    appointmentDiscussionNotes: string | null,
    discountCode: string | null,
    isTermsAndConditionsAgree: boolean
    [key: string]: any;
}

export const resetCustomerAppointmentData = () => ({
    firstName: "",
    lastName: "",
    mobilePhoneNo: "",
    email: "",
    dateOfBirth: '',
    address1: null,
    address2: null,
    address3: null,
    eircode: null,
    notes: null,
    occupation: "",
    isPreviousCreditIssues: false,
    incomeBandId: null,
    companyName: null,

    id: null,
    advisorId: null,
    customerId: null,
    appointmentDate: new Date(),
    appointmentStatusId: 6,
    serviceId: null,
    durationId: null,
    isActive: true,
    mortgageAmountRequired: null,
    mortgageTypeId: null,
    appointmentDiscussionNotes: null,
    customerDetails: null,
    discountCode: null,
    isTermsAndConditionsAgree: false
});