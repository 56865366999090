import { IStandardAutocomplete } from "../../_common/interface/autocomplete/iStandardAutocomplete";
import { dropdownService } from "../../_services/dropdown.service";
import StandardAutoComplete from "./standard.autocomplete";

interface IGenericAutoComplete extends IStandardAutocomplete {
    dataType: string;
    label?: string;
}

export const GenericAutoComplete = (params: IGenericAutoComplete) => {

    return (

        <StandardAutoComplete
            {...params}
            itemsLoader={() => dropdownService.fetchData(params.dataType)}
            label={params.label ? params.label :""} />
    );
}