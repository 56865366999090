export interface IPaymentManage {
    id: number | null,
    discountCode: string | null,
    cardholderName: string | null,
    cardNumber: number | null,
    expiryMonth: number | null,
    expiryYear: number | null,
    ccv: number | null,
    [key: string]: any
}

export const resetPaymentData = () => ({
    id: null,
    discountCode: null,
    cardholderName: null,
    cardNumber: null,
    expiryMonth: null,
    expiryYear: null,
    ccv: null,
 })