import { Box, Grid, Paper, Typography } from "@mui/material";
import { CATEGORY_NAV, IPrimaryNavInterface } from "../../_const/navigation.const";
import { PRIMARY_COLOR } from "../../_const/color.const";
import { useNavigate } from "react-router-dom";

import { handleErrorResponse } from "../../_helpers/handleResponse.helper";
import { Fragment, useEffect, useState } from "react";
import { appointmentService } from "../../_services/appointment.service";


export function CategorySelectionIndex() {
    let navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState<Array<IPrimaryNavInterface>>([]);
    const [focusedItem, setFocusedItem] = useState(null);


    useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {
        return appointmentService.getActiveServices()
            .then(
                (serv: any) => {
                    const categoryNav = CATEGORY_NAV;
                    let publicServices: IPrimaryNavInterface[] = [];

                    // Take a look and see which are public/active
                    // Show those only
                    categoryNav.forEach((x: IPrimaryNavInterface) => {
                        let serviceIndex = serv.findIndex((s: any) => s.name === x.name && s.isPublic === true);

                        if (serviceIndex !== -1) {
                            publicServices.push(x);
                        }
                    });

                    setData(publicServices);
                }, handleErrorResponse)
            .finally(() => {
                setLoading(false);
            });
    }

    function handleIconChange(index: any) {
        setFocusedItem(index);
    };

    return (

        <Box
            sx={{
                width: { sm: "608px", lg: '930px' },
                flexWrap: 'wrap',
                bgcolor: '#FFFFFF',
                borderRadius: { xs: 'none', sm: '15px', md: '40px!important' },
                boxShadow: { xs: 'none', sm: '0px 3px 40px #9CA7B233' },
                opacity: 1,
                paddingBottom: "20px",
                paddingLeft: { sm: "20px", lg: "0px" },
                paddingRight: {sm: "20px", lg: "0px"}

            }}
        >
            <Typography fontSize={{ xs: "16px", sm: "16px", md: "20px", lg: "20px", xl: "20px" }}
                color="#707070"
                fontWeight="Bold"
                fontFamily="Poppins"
                marginTop={{xs: "15px", md: "48px"}}
                marginLeft={{xs: "30px", sm: "30px", md: "48px"}}
                marginBottom="10px"
                textAlign="left"
            >
                Please select service:</Typography>

            <Box display="flex" justifyContent={{ xs: "center", sm: "center", md: "center" }}
                flexDirection="row"
                mb={{ xs: "15px", md: "0px" }}
                mt={{ xs: "0px", md: "0px" }}
                p={0} flexWrap="wrap"

                flexBasis="33.333333%"
                columnGap={{ xs: "15px", sm: "5px", md: "9px" }}
                rowGap={{xs: "9px", sm: "5px", md: "0px", lg: "9px"}}>

                {data && data.length > 0 && data.map((nav, index) => (
                    // Need <Fragment> to use key prop 
                    <Fragment key={index}>
                        <Paper elevation={2}
                            key={index}
                            onMouseOver={() => handleIconChange(index)}
                            onMouseOut={() => handleIconChange(null)}
                            sx={{
                                cursor: "pointer",
                                width: { xs: '156px', sm: '170px', md: '190px', lg: '246px', xl: '246px' },
                                height: { xs: '127px', sm: '127px', md: '127px', lg: '149px', xl: '149px' },
                                borderRadius: '20px',
                                margin: { sx: '10px auto', sm: '5px auto', md: '5px auto', lg: '15px', xl: '15px' },
                                '&:hover': {
                                    color: '#fff',
                                    backgroundColor: PRIMARY_COLOR[0],
                                },
                                boxShadow: "5px 5px 20px #9CA7B24D",
                            }}

                            onClick={(e) => navigate(nav.url)}

                        >
                            <Box
                                key={nav.name}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    transition: 'background-color 0.3s ease',
                                    '&:hover': {
                                        color: '#fff'
                                    },
                                    paddingBottom: '10px',
                                    fontWeight: '750',
                                    fontSize: '1.4em',
                                    lineHeight: '30px',
                                    letterSpacing: '0.2px',
                                    textAlign: 'center',
                                    fontFamily: "Poppins, sans-serif",
                                }}>
                                <Typography
                                    fontSize={{ xs: "14px", sm: "14px", md: "14px", lg: "20px", xl: "20px" }}
                                    fontWeight="600"
                                    fontFamily="Poppins"
                                    marginTop="16.79px">
                                    {nav.name}
                                </Typography>
                                <Typography
                                    fontSize={{ xs: "14px", sm: "14px", md: "14px", lg: "20px", xl: "20px" }}
                                    fontWeight="600"
                                    fontFamily="Poppins">
                                    Consultation
                                </Typography>

                            </Box>

                            <Box
                                key={nav.imageLocation}
                                display="flex"
                                m="0 auto"
                                component="img"
                                height={{ xs: "49px", md: "55px" }}
                                alt={nav.name}
                                src={focusedItem === index ? nav.whiteImageLocation : nav.imageLocation}
                            />

                        </Paper>
                    </Fragment>
                ))}

            </Box>      
        </Box>
    );
};
