import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Stepper } from '@mui/material';
import { PRIMARY_COLOR } from '../../_const/color.const';

const CssStepper = styled(Stepper)({
    ' &.MuiStepper-root': {
        justifyContent: "center",
        marginTop: "48px",
        padding: 0,
        '@media (max-width: 600px)': {
            margin: '0 auto',
            width: '90%'
        }
    },
    '.MuiStep-root': {
        width: "100%",
        paddingLeft: "0px",
        paddingRight: "0px"
    },
    ".MuiSvgIcon-root": {
        width: "48px",
        height: "48px",
        borderRadius: "50%",
        border: "2px solid #707070",
        zIndex: 99,
        '@media (max-width: 600px)': {
            width: '40px',
            height: '40px',
            border: "2px solid #707070"
        }
    },
    ".MuiSvgIcon-root:not(.Mui-completed)": {
        color: "white",
        fontSize: 'large',
        '@media (max-width: 600px)': {
            width: '35px',
            height: '35px',

        }
    },
    ".MuiStepIcon-text": {
        fill: "#707070 !important",
        fontWeight: 500
    },
    ".MuiSvgIcon-root.Mui-active": {
        color: PRIMARY_COLOR[5],
        borderRadius: "50%",
        border: "none",
        width: "50px",
        height: "50px",
        '@media (max-width: 600px)': {
            width: '40px',
            height: '40px',
        }
    },
    ".MuiSvgIcon-root.Mui-completed": {  /*completed step circle*/
        zIndex: 100,
        backgroundColor: 'white',
        border: "none",
        width: "50px",
        height: "50px",
        '@media (max-width: 600px)': {
            width: '40px',
            height: '40px',

        }
    },

    ".Mui-active .MuiStepIcon-text": {
        fill: "white !important",
    },
    "& .MuiStepConnector-line": {
        borderTopWidth: "2px",
        borderColor: "#707070",
        color: "#707070",
    },
    "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
        borderColor: PRIMARY_COLOR[0],
        width: "120%"
    },
    "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
        borderColor: PRIMARY_COLOR[0],
    },
    ".MuiStepConnector-root": {
        top: "20px",
        '@media (max-width: 600px)': {
            top: '0.8em',
        },


    },
    ".MuiStepLabel-label": {
        color: "#707070 !important",
        fontFamily: "Poppins, sans-serif",
        fontSize: "16px !important",
        fontWeight: "normal !important",
        marginTop: "-73px !important", /*move labels above stepper*/
        '@media (max-width: 600px)': {
            marginTop: "-60px !important",
            fontSize: "12px !important",
        }
    },

});

export default function StyledStepper(props: {
    children: React.ReactNode,
    activeStep?: number,
    alternativeLabel?: boolean,
    width?: string
}) {
    return (
        <CssStepper
            activeStep={props.activeStep}
            alternativeLabel={props.alternativeLabel}

        >
            {props.children}
        </CssStepper>
    );
}