import { Box, Grid, Typography } from "@mui/material";
import { PRIMARY_COLOR } from "../../../_const/color.const";

export const AppointmentCancellationWarning = () => {

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" padding={1 }>
            <Typography sx={{ color: PRIMARY_COLOR[5] }} align="center" fontSize={{xs: "20px", md: "28px"}} fontFamily="Poppins" marginTop={{ xs: "50px", md: "auto" }}>
                Appointment cannot be cancelled within 48 hours of the appointment time!
            </Typography>
        </Box>
    );
}