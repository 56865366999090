import { API_URL } from "../_config/config.const";
import { getBody } from "../_helpers/body.helper";
import { handleResponse } from "../_helpers/handleResponse.helper";
import { getHeader } from "../_helpers/header.helper";
import { getQuery } from "../_helpers/query.helper";

const API_NAME = "services";

export const servicesService = {
    getAll,
    get,   
    submit(model?: any) { return model?.id ? put(model) : post(model) },
    disable,
    getServiceByServiceTypeEnum
};

function getAll(query: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetAll?${getQuery(query)}`, requestOptions).then(handleResponse);
}

function get(id: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/Get/?id=${id}`, requestOptions).then(handleResponse);
}

function post(model: any) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/create`, requestOptions).then(handleResponse);
}

function put(model: any) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/update`, requestOptions).then(handleResponse);
}

function disable(id: any) {
    const requestOptions = { method: 'DELETE', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/disable/?serviceId=${id}`, requestOptions).then(handleResponse);
}

function getServiceByServiceTypeEnum(enumId: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetServiceByServiceTypeEnum/?enumId=${enumId}`, requestOptions).then(handleResponse);
}