import { JourneyStepsEnum } from "../../../_common/enum/journeySteps.enum";
import { ServiceTypeEnum } from "../../../_common/enum/setviceTypeEnum";

export interface IStepsInterface {
    name: string,
    step: number,
    page: JourneyStepsEnum
}

export const PAYMENTSTEPS = (serviceType: ServiceTypeEnum) => {

    const steps: IStepsInterface[] = [
        { name: 'Service', step: 1, page: JourneyStepsEnum.ServiceDetailsStep },
        {
            name: serviceType === ServiceTypeEnum.Mortgage ? 'Mortgage' : "Income", step: 2, page: JourneyStepsEnum.InitialDetailsStep
        },
        { name: 'Time', step: 3, page: JourneyStepsEnum.AppointmentPickerStep },
        { name: 'Details', step: 4, page: JourneyStepsEnum.CustomerDetailsStep },
        { name: 'Payment', step: 5, page: JourneyStepsEnum.PaymentStep }
    ];

    return steps;
};

export const FREESTEPS: IStepsInterface[] = [
    { name: 'Service', step: 1, page: JourneyStepsEnum.ServiceDetailsStep },
    { name: 'Income', step: 2, page: JourneyStepsEnum.InitialDetailsStep },
    { name: 'Time', step: 3, page: JourneyStepsEnum.AppointmentPickerStep },
    { name: 'Details', step: 4, page: JourneyStepsEnum.CustomerDetailsStep },
];

export const EXISTINGSTEPS: IStepsInterface[] = [
    { name: 'Service', step: 1, page: JourneyStepsEnum.ServiceDetailsStep },
    { name: 'Time', step: 2, page: JourneyStepsEnum.AppointmentPickerStep },
    { name: 'Details', step: 3, page: JourneyStepsEnum.CustomerDetailsStep },
];

export const TESTSTEPS: IStepsInterface[] = [
    { name: 'Service', step: 1, page: JourneyStepsEnum.ServiceDetailsStep },
    { name: 'Details', step: 2, page: JourneyStepsEnum.CustomerDetailsStep },
];

export const MORTGAGE_IMAGE = { imgUrl: 'mortgage-consultation-no-price.jpg', imageAltText: 'Mortgage Consultation' }

export const SERVICE_IMAGE = (serviceType: ServiceTypeEnum) => {
    //const icon: string = serviceType === ServiceTypeEnum.Mortgage ? require('../../../_assets/images/whiteIconsLarge/Icon_Mortgage_consultation_large.png') :
    //    serviceType === ServiceTypeEnum.Business ? require('../../../_assets/images/whiteIconsLarge/Icon_Business_Owner_consultation_large.png') :
    //        serviceType === ServiceTypeEnum.Existing ? require('../../../_assets/images/whiteIconsLarge/Icon_Existing_clients_large.png') :
    //            serviceType === ServiceTypeEnum.Financial ? require('../../../_assets/images/whiteIconsLarge/Icon_Financial_planning_large.png') :
    //                serviceType === ServiceTypeEnum.Investment ? require('../../../_assets/images/whiteIconsLarge/Icon_Investment_consultation_large.png') :
    //                    serviceType === ServiceTypeEnum.Pension ? require('../../../_assets/images/whiteIconsLarge/Icon_Pension_consultation_large.png') :
    //                        //serviceType === ServiceTypeEnum.Protection ? require('../../../_assets/images/whiteIconsLarge/Icon_Protection_consultation_large.png') :
    //                        serviceType === ServiceTypeEnum.Protection ? require('../../../_assets/images/svgIcons/Icon_Protection_consultation_white.png') :

    //                            require('../../../_assets/images/whiteIconsLarge/Icon_Mortgage_consultation_large.png');

    //return icon;
    const icon: string = serviceType === ServiceTypeEnum.Mortgage ? require('../../../_assets/images/svgWhiteIcons/Icon_Mortgage_consultation_white.svg').default :
        serviceType === ServiceTypeEnum.Business ? require('../../../_assets/images/svgWhiteIcons/Icon_Business_Owner_consultation_white.svg').default :
            serviceType === ServiceTypeEnum.Existing ? require('../../../_assets/images/svgWhiteIcons/Icon_Existing_clients_white.svg').default :
                serviceType === ServiceTypeEnum.Financial ? require('../../../_assets/images/svgWhiteIcons/Icon_Financial_planning_white.svg').default :
                    serviceType === ServiceTypeEnum.Investment ? require('../../../_assets/images/svgWhiteIcons/Icon_Investment consultation_white.svg').default :
                        serviceType === ServiceTypeEnum.Pension ? require('../../../_assets/images/svgWhiteIcons/Icon_Pension_consultation_white.svg').default :
                            serviceType === ServiceTypeEnum.Protection ? require('../../../_assets/images/svgWhiteIcons/Icon_Protection_consultation_white.svg').default :

                                require('../../../_assets/images/whiteIconsLarge/Icon_Mortgage_consultation_large.png');

    return icon;

};