import { Step, StepConnector, StepLabel, Stepper } from "@mui/material";
import { useState } from "react";
import MyStepper from "../../../_components/stepper/stepConnector";
import StyledStepper from "../../../_components/stepper/styledStepper";
import { IStepsInterface } from "../consts/serviceConsts";

export const JourneyStepper = (params: { steps: Array<IStepsInterface>, activeStep: number }) => {


    return (
        <StyledStepper activeStep={params.activeStep - 1} alternativeLabel={true}>
            {params.steps && params.steps.length > 0 && params.steps.map((step, index) => (
                <Step key={step.name}>
                    <StepLabel>{step.name}</StepLabel>
                </Step>
            ))}
        </StyledStepper>


    );
}