import * as React from 'react';
import { styled } from '@mui/material/styles';
import { FormControlLabel } from '@mui/material';
import { PRIMARY_COLOR } from '../../_const/color.const';

const CssFormControlLabel = styled(FormControlLabel)({

    '& .MuiFormControlLabel-label': {
        width: "178px",
        height: "34px",
        background: '#3BC1CD 0% 0% no-repeat padding-box', 
        borderRadius: '5px',
        opacity:1,
        color: '#fff',
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',  
        verticalAlign: 'middle',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    '& .MuiFormControlLabel-root': {
        margin: 0,
        padding: 0
    },
    '& .MuiGridRoot': {
        margin: 0,
        padding: 0
    },
});

export default function StyledGrid(props: {
    key?: number | string | null | undefined,
    value?: unknown,
    control: React.ReactElement,
    label?: string | null,
    name?: string
}) {
    return (

        <CssFormControlLabel
            key={props.key}
            value={props.value}
            control={props.control}
            label={props.label}
            name={props.name}
        />
    );
}