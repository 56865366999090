import { API_URL } from "../_config/config.const";
import { getBody } from "../_helpers/body.helper";
import { handleResponse } from "../_helpers/handleResponse.helper";
import { getHeader } from "../_helpers/header.helper";

const API_NAME = "payment";

export const paymentService = {
    createPaymentIntent,
};

function createPaymentIntent(amount: number, receiptEmail: string) {
    const requestOptions = { method: 'POST', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/create-payment-intent/?amount=${amount}&receiptEmail=${receiptEmail}`, requestOptions).then(handleResponse);
}

