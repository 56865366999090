import { accountService } from "../_services/accounts.service";

export function authHeader(): any {
  // return authorization header with jwt token
  const currentUser = accountService.currentUserValue;

  //return { Authorization: `Bearer ${currentUser.token}` };
  if (currentUser && currentUser.token) {
    return { Authorization: `Bearer ${currentUser.token}` };
  }

  return {};
}

export function getHeader() {

  let header = authHeader();
  header["Content-Type"] = 'application/json';

  return header;
}

export function getUploadHeader() {
  let header = authHeader();
  header['content-type'] = 'multipart/form-data';
  return header;
}


