import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../_components/button/primary.button";
import { PRIMARY_COLOR } from "../../../_const/color.const";
import { ICustomerAppointmentManage } from "../../../_common/interface/customerAppointment/iCustomerAppointmentManage";

export const ErrorPage = (params: {
    
}) => {
    let navigate = useNavigate();

    return (
        <>
            <Grid container>
                <Grid item xs={0} md={3}></Grid>
                <Grid item xs={12} md={6}>
                    <Typography align="center">
                        <Box sx={{ color: PRIMARY_COLOR[0] }}>
                            <h1>Oops, an error has occurred.</h1>
                        </Box>
                        <Box sx={{ paddingBottom: '25px'}}>
                            Please try booking again.
                        </Box>
                    </Typography>
                    <Box sx={{ paddingBottom: '25px'}}> 
                    <PrimaryButton
                        variant="fairstone"
                        type="button"
                        onClick={(e) => navigate('/')}
                    >
                        Book Another Appointment
                    </PrimaryButton></Box>
                </Grid>
                <Grid item xs={0} md={3}></Grid>
                
            </Grid>
        </>
    );
}

