import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../_components/button/primary.button";
import { PRIMARY_COLOR } from "../../../_const/color.const";
import { ICustomerAppointmentManage } from "../../../_common/interface/customerAppointment/iCustomerAppointmentManage";

export const AppointmentConfirmedStep = (params: {
    data?: ICustomerAppointmentManage
}) => {
    let navigate = useNavigate();

    return (
        <Box display="flex" flexDirection="column" justifyItems="center" alignItems="center" gap="20px">
            <Box>
                <Typography textAlign="center" marginTop="50px" marginBottom="20px" sx={{ color: PRIMARY_COLOR[0] }} fontFamily="Poppins, sans-serif" fontWeight="bold" variant="h4">Your appointment is now confirmed!</Typography>
                <Typography textAlign="center" fontFamily="Poppins, sans-serif" fontWeight="bold">An email will arrive in your inbox confirming your appointment details.</Typography>

                <Typography textAlign="center" marginTop="20px" sx={{ color: PRIMARY_COLOR[0] }} fontFamily="Poppins, sans-serif" fontWeight="bold" variant="h4">
                    Thank you!
                </Typography>
            </Box>

            <Box display="flex" justifyContent="center">
                <PrimaryButton
                    variant="fairstone"
                    type="button"
                    onClick={(e) => navigate('/')}
                >
                    <Box><Typography fontSize="14px" fontWeight="bold">Book Another Appointment</Typography></Box> 
                </PrimaryButton>
            </Box>
        </Box>
    );
}

