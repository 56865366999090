import { createTheme } from "@mui/material";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../_const/color.const";

export const theme = createTheme({
    palette: {
        primary: {
            main: PRIMARY_COLOR[0],
        },
        secondary: {
            main: SECONDARY_COLOR[0]
        }
    }
});