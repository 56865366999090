import { Box, CircularProgress, List, ListItem, ListItemIcon, Typography, useMediaQuery } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { GREY_COLOR, PRIMARY_COLOR } from "../../../_const/color.const";
import { Fragment } from "react";

export const ServiceDetailsStep = (params: {
    serviceDetails: string,
    servicePrice: number
}) => {

    const isMobile = useMediaQuery('(max-width:600px)');

    const renderListItems = (htmlString: string) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;

        const listItems = tempElement.querySelectorAll('li');

        return Array.from(listItems).map((li, index) => (
            <ListItem
                sx={{
                    marginLeft: { xs: "10px", md: "48px" },
                    marginRight: { xs: "5px", md: "48px" },
                    paddingRight: { xs: "0px", md: "80px" },
                    paddingLeft: "0px",
                    marginBottom: "15px",
                    width: {xs: "90%", md: "100%"}
                }}
                key={index}>
                <ListItemIcon sx={{ marginTop: "2px", alignSelf: 'flex-start', minWidth: "30px" }}>
                    <CheckCircleIcon sx={{ color: PRIMARY_COLOR[0], fontSize: "18px !important" }} />
                </ListItemIcon>
                <Typography fontFamily="Poppins" fontSize={{ xs: "14px", md: "16px" }}>{li.textContent}</Typography>
            </ListItem>
        ));
    };

    const renderParagraphs = (htmlString: string) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;

        const paragraphs = tempElement.querySelectorAll('p');

        return Array.from(paragraphs).map((p, index) => (
            <Box key={index}
                ml={{ xs: "12px", md: "50px" }}
                mr={{ xs: "10px", md: "48px" }}
                mt="25px"
            >
                <Typography fontFamily="Poppins" fontSize={{ xs: "14px", md: "16px" }}>{p.textContent}</Typography>
            </Box>
        ));
    };

    return (
        <>
            {renderListItems(params.serviceDetails)}
            {renderParagraphs(params.serviceDetails)}
        </>

    );
}


